import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { currentUserProfileQuery } from "../services/userAPI";
import "./resuableHeader.css";
import Icon2 from "../Images/icon2.png";
import HeaderCloseIcon from "../Images/header-circle-image.png";
import HeaderOpenIcon from "../Images/header-circle-down.png";
import HeaderOpenW from "../Images/expand_circle-down-white-color.png";
import Logout from "../Images/logout-icon.png";
import { IoIosLogOut } from "react-icons/io";
import { Menu, X } from "lucide-react";
import { Button } from "antd";
import Lessthan from "../Images/lessthan_Icon.png"
import CreateIconPlus from "../Images/createplus_icon.png"
import LoadingImage from "../Images/icon2.png"
import Home from "../Images/home-image.png";
import CreateFolder from "../Images/create_new_folder_image.png";


const ReusableHeader = ({ setIsAuthenticated = () => { } }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const location = useLocation();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(currentUserProfileQuery, {
    skip: !!userData,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.currentUserProfile) {
      setUserData(data.currentUserProfile);
    }
  }, [data]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const logout = () => {
    localStorage.removeItem("flutter.accessToken");
    setIsAuthenticated(false);
    setUserData(null);
    window.location.href = "/login-page";
  };

  const truncateDesignation = (designation) => {
    const words = designation.split(" ");
    if (words.length > 2) {
      return `${words.slice(0, 2).join(" ")}...`;
    }
    return designation;
  };

  <div className="loading-container">
    <div className="spinner"></div>
  </div>

  if (error) return <p>Error: {error.message}</p>;

  const getIcon = () => {
    if (isDropdownOpen) {
      return HeaderOpenIcon;
    }
    if (isHovered) {
      return HeaderOpenW;
    }
    return HeaderCloseIcon;
  };
  const handleDiscardClick = () => {
    navigate("/admin");
  };
  

  return (
    <header className="header-container">

      <div className="logo-container">
        {!(isMobile && location.pathname === "/create") && (
          <img src={Icon2} alt="Logo" />
        )}
      </div>

      {isMobile && !(location.pathname === "/create") && (
        <div className="mobile-menu-button">
          {isMobileMenuOpen ? (
            <X className="menu-icon" onClick={toggleMobileMenu} />
          ) : (
            <Menu className="menu-icon" onClick={toggleMobileMenu} />
          )}
        </div>
      )}

      {!isMobile && location.pathname === "/create" || location.pathname === "/create-client-form" ? (
        <></>
      ) : (
        userData && (
          <div className={`profile-dropdown ${isDropdownOpen ? "open" : ""}`}>
            <div
              className="profile-button"
              onClick={toggleDropdown}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="profile-content">
                <img src={getIcon()} alt="Toggle" className="header-icon" />
                <div className="text-container">
                  <span className="profile-name">{userData.name}</span>
                  <span
                    className="profile-designation"
                    title={userData.designation}
                  >
                    {truncateDesignation(userData.designation)}
                  </span>
                </div>
                <img
                  src={userData.profilePicture || "default-profile-image.png"}
                  alt="User"
                  className="profile-image"
                />
              </div>
            </div>
            <button className="logout-button" onClick={logout}>
              <img src={Logout} alt="Logout" className="logout-icon" />
              LOGOUT
            </button>
          </div>
        )
      )}

      {isMobile && (
        <div className={`mobile-menu ${isMobileMenuOpen ? "active" : ""}`}>
          <div className="mobile-menu-header">
            <span style={{ fontSize: "large", fontFamily: "Inter" }}>Close</span>
            <X className="menu-close-icon" onClick={toggleMobileMenu} />
          </div>
          {userData && (
            <div className="mobile-profile-info">
              <img
                src={userData.profilePicture || "default-profile-image.png"}
                alt="User"
                className="is-mobile-profile-image"
              />
              <div className="is-mobile-text-container">
                <span className="is-mobile-profile-name">{userData.name}</span>
                <span className="is-mobile-profile-designation">{truncateDesignation(userData.designation)}</span>
              </div>
            </div>
          )}
          <button className="mobile-menu-item active" onClick={() => {
            navigate('/admin');
            toggleMobileMenu();
          }}>
            <span>Admin</span>
            <img
              src={Home}
              alt="Home"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "7%",
              }}
            />
          </button>
          <button className="mobile-menu-item" onClick={() => {
            navigate('/create');
            toggleMobileMenu();
          }}>
            <span>Create</span>
            <img
              src={CreateFolder}
              alt="Create"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "7%",

              }}
            />
          </button>
          <button className="mobile-logout-button" onClick={logout}>
            <span>Logout</span>
            <IoIosLogOut className="logout-icon" />

          </button>
        </div>
      )}


    </header>
  );
};

export default ReusableHeader;
