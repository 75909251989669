import { useEffect, useRef, useState } from "react";
import { Search } from "lucide-react";
import "./searchableDropdownField.css";

const SearchableDropdown = ({
  options = [],
  label,
  id,
  selectedVal,
  handleChange,
  placeholder,
  onFocus,
  disabled
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isBackspacing, setIsBackspacing] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const inputRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsOpen(false);
        setQuery("");
        setIsBackspacing(false);
        setHighlightedIndex(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const toggleDropdown = (e) => {
    e.preventDefault();
    if (!isOpen && onFocus) {
      onFocus();
    }
    setIsOpen((prev) => !prev);
  };

  const selectOption = (option) => {
    handleChange(option[id]);
    setIsOpen(false);
    setQuery("");
    setIsBackspacing(false);
    setHighlightedIndex(null);
  };

  const getDisplayValue = () => {
    if (selectedVal) {
      const selectedOption = options.find((option) => option[id] === selectedVal);
      return selectedOption ? selectedOption[label] : "";
    }
    return "";
  };

  const filterOptions = () => {
    return options.filter(
      (option) => option[label]?.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
  };

  const handleKeyDown = (e) => {
    const optionsList = filterOptions();
    const numberOfOptions = optionsList.length;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (highlightedIndex === null || highlightedIndex === numberOfOptions - 1) {
        setHighlightedIndex(0);
      } else {
        setHighlightedIndex(highlightedIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (highlightedIndex === null || highlightedIndex === 0) {
        setHighlightedIndex(numberOfOptions - 1);
      } else {
        setHighlightedIndex(highlightedIndex - 1);
      }
    } else if (e.key === "Enter" && highlightedIndex !== null) {
      selectOption(optionsList[highlightedIndex]);
    }
  };

  return (
    <div className={`dropdown ${disabled ? "disabled-dropdown" : ""} ${isOpen ? "open" : ""}`} ref={inputRef}>
      <div className="control" onClick={toggleDropdown}>
        <div className="selected-value">
          <input
            type="text"
            value={getDisplayValue()}
            placeholder={placeholder || "Select"}
            readOnly
            disabled={disabled}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : "close"}`}>
        <div className="search-container">
          <Search className="search-icon" size={16} />
          <input
            ref={searchInputRef}
            type="text"
            className="search-input"
            placeholder="Find roles"
            value={query}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        {filterOptions().length > 0 ? (
          filterOptions().map((option, index) => (
            <div
              onClick={() => selectOption(option)}
              className={`option ${option[id] === selectedVal ? "selected" : ""} ${highlightedIndex === index ? "highlighted" : ""}`}
              key={`${id}-${index}`}
            >
              {option[label]}
            </div>
          ))
        ) : (
          <div className="no-options">No matching options</div>
        )}
      </div>
    </div>
  );
};

export default SearchableDropdown;