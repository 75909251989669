import React, { useState, useEffect } from "react";
import { Form, Button, message, Layout, Row, Col, Upload, Checkbox } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { emailRegex, validationRegexAddress, validationRegexMobileNo, validationRegexOnlyAlpha } from "../regex/regex";
import { Account_Form_Query, Role_Account_Query, Get_Lense_Account_Projects, Upload_Document, update_Account, Role_Management, Get_All_Job_Role } from "../services/userAPI"; // Import your mutation
import { Typography, Box, Paper, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import TextInputField from "../reusableField/TextInputField2";
import Arrow from "../Images/arrow_circle_right.png";
import Vector from "../Images/Vector.png";
import "./createClientForm.css";
import { ChromePicker } from "react-color";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const CreateClientForm = () => {
    const { id } = useParams();
    const location = useLocation();
    const accountId = id || location.state?.id;
    const { projectsData: passedProjectsData } = location.state || {};
    const addMode = location.state?.addMode;
    const [open, setOpen] = useState(false);
    const [openRoles, setRolesOpen] = useState(false);
    const [uploadedFilename, setUploadedFilename] = useState(null);
    const [color, setColor] = useState("#FFAD01");
    const [showPicker, setShowPicker] = useState(false);
    const [clientImage, setClientImage] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [editMode, setEditMode] = useState(!!accountId);
    const [currentRole, setCurrentRole] = useState(null);
    const [formData, setFormData] = useState({
        id: location.state?.id || "",
        name: location.state?.name || "",
        imageUrl: location.state?.imageUrl || "",
        mainLogoUrl: location.state?.mainLogoUrl || "",
        address: location.state?.address || "",
        role: location.state?.role || "",
        companyURL: location.state?.url || "",
        address2: location.state?.address2 || "",
        roles: [],
        emailLogin: location.state?.emailLogin || false,
        numberLogin: location.state?.numberLogin || false,
        selfRegistration: location.state?.selfRegistration || false,
        brandColor: location.state?.brandColor || "#FFAD01",
        addRoleRoleName: "",
        addRolePocName: "",
        addRolePocContactNumber: "",
        addRolePocEmail: "",
        lense: false,
        darts: false,
        clan: false,
        mandatoryTimeDistribution: false,
        dartsForm: false,
        kp1TargetDays: "",
        editDays: "",
        // pocEmail: "",
        // pocContactNumber: "",
    });

    const [uploadDocument] = useMutation(Upload_Document, {
        onCompleted: (data) => {
            if (data.uploadDocument.success) {
                const filename = data.uploadDocument?.data?.data?.filename;
                const imageUrl = data.uploadDocument?.data?.data?.url;
                setUploadedFilename(filename);

                setFormData((prevData) => ({
                    ...prevData,
                    imageUrl: imageUrl,
                }));
                message.success("Image uploaded successfully!");
            } else {
                message.error("Failed to upload image: " + data.uploadDocument.message);
            }
        },
        onError: (error) => {
            message.error("Upload failed: " + error.message);
        },
    });

    const { data: rolesData, loading: rolesLoading, error: rolesError } = useQuery(Role_Account_Query, {
        variables: { accountId: parseInt(accountId) },
        skip: !accountId,
    });

    const navigate = useNavigate();
    const [addAccount, { error }] = useMutation(Account_Form_Query, {
        onCompleted: (data) => {
            message.success("Client created successfully!");
            navigate("/create");
        },
        onError: (error) => {
            // message.error("Failed to create client: " + error.message);
            message.error("Something went wrong");
        },
    });

    const handleImageChange = ({ file }) => {
        if (!file) {
            message.error("Invalid file object.");
            return;
        }

        const allowedFormats = ["image/jpeg", "image/png"];
        const actualFile = file.originFileObj || file;

        if (!actualFile) {
            message.error("No valid file detected.");
            return;
        }

        if (!allowedFormats.includes(actualFile.type)) {
            message.error("Invalid file format. Only JPEG and PNG are allowed.");
            return;
        }

        try {
            // Create a preview of the image
            const imageUrl = URL.createObjectURL(actualFile);
            setClientImage(imageUrl);

            // Upload the actual file
            uploadDocument({
                variables: {
                    s3folder: "COMPANY_LOGO",
                    document: actualFile, // Pass the raw file object here
                },
                context: {
                    useMultipart: true, // Ensure the Apollo Client supports multipart file uploads
                },
            });
        } catch (error) {
            console.error("Error handling the file:", error);
            message.error("File upload failed.");
        }
    };

    const { data, loading } = useQuery(Get_Lense_Account_Projects, {
        variables: { clientId: String(accountId) },
        skip: !accountId && !passedProjectsData,
    });

    const projectsData = passedProjectsData || data?.getLenseAccountProjects;

    const handleInputChange = ({ target: { name, value } }) => {
        if (name === "role.name") {
            setFormData((prevData) => ({
                ...prevData,
                role: value,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleEmailLoginChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            clan: e.target.checked,
        }));
    };

    const handleMandatoryProjectTime = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            mandatoryTimeDistribution: e.target.checked,
        }));
    };

    const handleNumberLoginChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            dartsForm: e.target.checked,
        }));
    };

    const handleEmailChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            emailLogin: e.target.checked,
            numberLogin: !e.target.checked,
            selfRegistration: formData.selfRegistration // Ensure the other checkbox is deselected
        }));
    };

    const handleNumberChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            emailLogin: !e.target.checked, // Ensure the other checkbox is deselected
            numberLogin: e.target.checked,
            selfRegistration: formData.selfRegistration // Ensure the other checkbox is deselected

        }));
    };

    const handleSelfRegistrationChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            selfRegistration: e.target.checked, // Toggle self-registration independently
        }));
    };

    const validateForm = () => {
        const requiredFields = [
            formData.name,
            formData.companyURL,
            formData.address,
            formData.address2,
            // formData.pocName,
            // formData.pocContactNumber,
            // formData.pocEmail,
            formData.brandColor,
            // formData.selfRegistration,
            uploadedFilename || formData.imageUrl || formData.mainLogoUrl,
        ];
        const allFieldsFilled = requiredFields.every(field => {
            if (typeof field === 'string') {
                return field.trim() !== "";
            }
            return field !== undefined && field !== null && field !== "";
        });
        const isLoginSelected = formData.emailLogin || formData.numberLogin;
        // const isSelfRegistrationSelected = formData.selfRegistration;
        return allFieldsFilled && isLoginSelected
    };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [formData]);

    const [updateClient] = useMutation(update_Account, {
        onCompleted: (data) => {
            if (data.updateAccount.success) {
                message.success("Client updated successfully!");
                navigate("/create");
            } else {
                message.error(data.updateAccount.message || "Failed to update client");
            }
        },
        onError: (error) => {
            message.error("Something went wrong: " + error.message);
        },
    });

    const onFinish = () => {
        let brandColor = formData.brandColor || "#FFAD01";
        brandColor = brandColor.startsWith("#") ? brandColor.slice(1) : brandColor; // Remove '#' if it exists

        if (editMode) {
            // Call the updateAccount mutation for editing
            updateClient({
                variables: {
                    accountId: parseInt(accountId),
                    name: formData.name,
                    address: formData.address,
                    address2: formData.address2,
                    url: formData.companyURL,
                    mainLogo: uploadedFilename || formData.mainLogoUrl, // Use uploaded file or existing one
                    smallLogo: uploadedFilename || formData.imageUrl || formData.mainLogoUrl,
                    emailLogin: formData.emailLogin,
                    numberLogin: formData.numberLogin,
                    brandColor: brandColor, // Send without '#'
                    selfRegistration: formData.selfRegistration,
                },
            });
        } else {
            // Existing create functionality
            addAccount({
                variables: {
                    name: formData.name,
                    address: formData.address,
                    address2: formData.address2,
                    url: formData.companyURL,
                    smallLogo: uploadedFilename,
                    mainLogo: uploadedFilename,
                    emailLogin: formData.emailLogin,
                    numberLogin: formData.numberLogin,
                    selfRegistration: formData.selfRegistration,
                    brandColor: brandColor, // Send without '#'
                },
            });
        }
    };

    useEffect(() => {
        return () => {
            if (clientImage) {
                URL.revokeObjectURL(clientImage);
            }
        };
    }, [clientImage]);

    useEffect(() => {
        if (rolesData && rolesData.getAllJobrole) {
            setFormData((prevData) => ({
                ...prevData,
                roles: rolesData.getAllJobrole.map((role) => ({
                    id: role.id,
                    name: role.name || "",
                    clanNeeded: role.clanNeeded || false,
                    dartsNeeded: role.dartsNeeded || false,
                    dartsFormNeeded: role.dartsFormNeeded || false,
                })),
            }));
        }
    }, [rolesData]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setFormData({
            addRoleRoleName: "",
            addRolePocName: "",
            addRolePocContactNumber: "",
            addRolePocEmail: "",
            kp1TargetDays: "",
            editDays: "",
            lense: false,
            darts: false,
            clan: false,
            dartsForm: false,
            mandatoryTimeDistribution: false,
        });
        setOpen(false);
    };

    const [addJobrole, { addRole: addRoleloading }] = useMutation(Role_Management, {
        onCompleted: (data) => {
            if (data.addJobrole.success) {
                message.success("Role added successfully!");
                setTimeout(() => {
                    setOpen(false); // Ensure dialog closes after the mutation completes
                }, 500);
                setFormData({
                    addRoleRoleName: "",
                    addRolePocName: "",
                    addRolePocContactNumber: "",
                    addRolePocEmail: "",
                    lense: false,
                    darts: false,
                    clan: false,
                    mandatoryTimeDistribution: false,
                    dartsForm: false,
                    kp1TargetDays: "",
                    editDays: "",
                });
            } else {
                message.error(data.addJobrole.message || "Failed to add role");
            }
        },
        onError: (error) => {
            message.error("Something went wrong: " + error.message);
        },
    });

    const handleSubmit = () => {
        const variables = {
            accountId: parseInt(accountId),
            name: formData.addRoleRoleName,
            clanNeeded: formData.clan,
            dartsNeeded: formData.darts,
            dartsFormNeeded: formData.dartsForm,
            jobroleId: null,
            pocName: formData.addRolePocName,
            pocEmail: formData.addRolePocEmail,
            pocNumber: formData.addRolePocContactNumber,
            mandatoryTimeDistribution: formData.mandatoryTimeDistribution,
            targetEditDays: parseInt(formData.kp1TargetDays, 10) || 0,
            inputEditDays: parseInt(formData.editDays, 10) || 0,
        };
        addJobrole({ variables })
            .then((response) => {
                if (response?.data?.addJobrole?.success) {
                    handleClose();
                    handleCloseAddRole(false); // Close the add role panel
                    refetch(); // Refetch job roles to get the updated list
                } else {
                    console.error("Error adding Job Role:", response?.data?.addJobrole?.message);
                }
            })
            .catch((err) => {
                console.error("Mutation Error:", err);
            });
    };

    const isRoleManagementFormValid = () => {
        const {
            addRoleRoleName,
            addRolePocName,
            addRolePocContactNumber,
            addRolePocEmail,
            kp1TargetDays,
            editDays,
            darts,
            dartsForm,
            lense,
            clan,
            mandatoryTimeDistribution,
        } = formData;

        // Basic validation for required fields
        if (
            !addRoleRoleName.trim() ||
            !addRolePocName.trim() ||
            !addRolePocContactNumber.trim() ||
            !addRolePocEmail.trim()
        ) {
            return false;
        }

        // Validate email and phone using regex
        if (!validationRegexMobileNo.test(addRolePocContactNumber) || !emailRegex.test(addRolePocEmail)) {
            return false;
        }

        if (darts && (!kp1TargetDays.trim() || !editDays.trim())) {
            return false;
        }

        // if (!mandatoryTimeDistribution) {
        //     return false;
        // }
        // if (!dartsForm) {
        //     return false;
        // }
        // if (!darts) {
        //     return false;
        // }
        // if (!lense) {
        //     return false;
        // }
        // if (!clan) {
        //     return false;
        // }
        return true;
    };

    const { JobRoleLoading, JobRoleError, data: JobRoleData, refetch } = useQuery(Get_All_Job_Role, {
        variables: { accountId },
        fetchPolicy: "network-only",
    });

    const roles = JobRoleData?.getAllJobrole || [];

    const handleEditClick = (role) => {
        setCurrentRole({
            id: role.id,
            name: role.name,
            address: role.account?.address || '',
            imageUrl: role.account?.mainLogoUrl || '',
            mainLogoUrl: role.account?.mainLogoUrl || '',
            emailLogin: role.account?.emailLogin || false,
            numberLogin: role.account?.numberLogin || false,
            mandatoryTimeDistribution: role.mandatoryTimeDistribution || false,
            brandColor: role.account?.brandColor || '',
            url: role.account?.url || '',
            address2: role.account?.address2 || '',
            selfRegistration: role.account?.selfRegistration || false,
            pocName: role.pocName || '',
            pocContactNumber: role.pocContactNumber || '',
            pocEmail: role.pocEmail || '',
            clanNeeded: role.clanNeeded || false,
            dartsNeeded: role.dartsNeeded || false,
            dartsFormNeeded: role.dartsFormNeeded || false,
            targetEditDays: role.targetEditDays || 0, // Use default value if null/undefined
            inputEditDays: role.inputEditDays ?? 0,
        });
        setRolesOpen(true); // Open the dialog
    };

    const handleCloseAddRole = () => {
        setRolesOpen(false);
        setCurrentRole(null);
        setOpen(false)
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCurrentRole((prevRole) => ({
            ...prevRole,
            [name]: checked,
        }));
    };

    const handleSave = () => {
        if (!currentRole) return;
        const {
            accountId,
            name,
            clanNeeded,
            dartsNeeded,
            dartsFormNeeded,
            id,
            pocName,
            pocEmail,
            pocContactNumber,
            mandatoryTimeDistribution,
            targetEditDays,
            inputEditDays,
        } = currentRole;

        addJobrole({
            variables: {
                accountId: accountId || 1,
                name,
                clanNeeded,
                dartsNeeded,
                dartsFormNeeded,
                jobroleId: id || null,
                pocName,
                pocEmail,
                pocNumber: pocContactNumber,
                mandatoryTimeDistribution: mandatoryTimeDistribution,
                targetEditDays: parseInt(targetEditDays, 10) || 0,
                inputEditDays: parseInt(inputEditDays, 10) || 0,
            },
        })
            .then((response) => {
                if (response?.data?.addJobrole?.success) {
                    handleClose();
                    handleCloseAddRole(false);
                } else {
                    console.error("Error adding Job Role:", response?.data?.addJobrole?.message);
                }
            })

            .catch((err) => {
                console.error("Mutation Error:", err);
            });
    };

    return (
        <Content style={{ margin: "16px", marginTop: "0px" }}>
            <div className="create-client-container">
                <div className="heading">{addMode ? "Add Client" : accountId ? "Edit Client" : "Create New Client"}</div>
                <Form.Item name="clientImage">
                    <div className="upload-container">
                        <Upload
                            name="clientImage"
                            showUploadList={false}
                            customRequest={handleImageChange}
                            accept="image/*"
                        >
                            <div className="image-upload-circle">
                                {clientImage ? (
                                    <img
                                        src={clientImage}
                                        alt="Uploaded"
                                        className="uploaded-image"
                                    />
                                ) : formData.imageUrl ? (
                                    <img
                                        src={formData.imageUrl}
                                        alt="Existing Client"
                                        className="uploaded-image"
                                    />
                                ) : formData.mainLogoUrl ? (
                                    <img
                                        src={formData.mainLogoUrl}
                                        alt="Existing Logo"
                                        className="uploaded-image"
                                    />
                                ) : (
                                    <img
                                        src={Vector}
                                        alt="Upload Icon"
                                        className="upload-icon"
                                    />
                                )}
                            </div>
                        </Upload>

                    </div>
                </Form.Item>

                <Row>
                    <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client Name</label>
                        <TextInputField
                            name="name"
                            value={formData.name}
                            placeholder="Enter Client Name"
                            feedbackMessage="Please input the client name."
                            validationRegex={validationRegexOnlyAlpha}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Website URL</label>
                        <TextInputField
                            name="companyURL"
                            value={formData.companyURL}
                            placeholder="Enter Website URL"
                            feedbackMessage="Please input the company URL."
                            validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client Office Address</label>
                        <TextInputField
                            name="address"
                            value={formData.address}
                            placeholder="Address 1"
                            feedbackMessage="Please input the address."
                            validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client Office Address 2</label>
                        <TextInputField
                            name="address2"
                            value={formData.address2}
                            placeholder="Address 2"
                            feedbackMessage="Please input the address 2."
                            validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                        <label style={{ fontSize: "15px", }}>Brand Color</label>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            {/* Input field */}
                            <input
                                type="text"
                                value={formData.brandColor}
                                placeholder="Brand Color"
                                onClick={() => setShowPicker(!showPicker)}
                                readOnly
                                style={{
                                    cursor: 'pointer',
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    width: '207px',
                                    position: 'relative',
                                }}
                            />

                            <div
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: (typeof formData.brandColor === "string" && formData.brandColor.startsWith("#"))
                                        ? formData.brandColor
                                        : `#${formData.brandColor || ''}`, // Ensure # prefix
                                    border: '1px solid #ccc',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    pointerEvents: 'none',
                                }}
                            ></div>

                            {/* Color picker */}
                            {showPicker && (
                                <div style={{ position: 'absolute', zIndex: '2', marginTop: '10px' }}>
                                    <ChromePicker
                                        styles={{
                                            default: {
                                                alpha: { display: 'none' },
                                                picker: { boxShadow: 'none' },
                                            },
                                        }}
                                        className="custom-chrome-picker"

                                        color={(typeof formData.brandColor === "string" && formData.brandColor.startsWith("#"))
                                            ? formData.brandColor
                                            : `#${formData.brandColor || ''}`}
                                        onChange={(selectedColor) => {
                                            const newColor = selectedColor.hex;
                                            setColor(newColor);
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                brandColor: newColor,
                                            }));
                                        }}
                                    />

                                    <button
                                        onClick={() => setShowPicker(false)}
                                        style={{
                                            marginTop: '5px',
                                            background: 'transparent',
                                            border: 'none',
                                            color: '#007BFF',
                                            cursor: 'pointer',
                                        }}
                                    >
                                    </button>
                                </div>
                            )}

                            {/* Overlay to close picker when clicking outside */}
                            {showPicker && (
                                <div
                                    onClick={() => setShowPicker(false)}
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 1,
                                    }}
                                >
                                </div>
                            )}
                        </div>
                    </Col>

                    {/* <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                            <label style={{ fontSize: "15px", color: "#1C1B1F" }}>POC Name</label>
                            <TextInputField
                                name="pocName"
                                value={formData.pocName}
                                placeholder="POC Name"
                                feedbackMessage="Please input the POC name."
                                validationRegex={validationRegexOnlyAlpha}
                                onChange={handleInputChange}
                            />
                        </Col>

                        <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                            <label style={{ fontSize: "15px", color: "#1C1B1F" }}>POC Contact Number</label>
                            <TextInputField
                                name="pocContactNumber"
                                value={formData.pocContactNumber}
                                placeholder="POC Contact Number"
                                feedbackMessage="Please input the POC contact number."
                                validationRegex={validationRegexMobileNo}
                                onChange={handleInputChange}
                            />
                        </Col>

                        <Col sm={12} xs={12} md={6} lg={5} style={{ marginBottom: "2%", marginRight: "2%" }}>
                            <label style={{ fontSize: "15px", color: "#1C1B1F" }}>POC Email</label>
                            <TextInputField
                                name="pocEmail"
                                value={formData.pocEmail}
                                placeholder=" POC Email"
                                feedbackMessage="Please input the POC mail."
                                validationRegex={emailRegex}
                                onChange={handleInputChange}
                            />
                        </Col> */}

                </Row>

                <Row>
                    <Col span={6}>
                        <div className="login-group">
                            <Form.Item>
                                <Checkbox
                                    checked={formData.emailLogin}
                                    onChange={handleEmailChange}
                                >
                                    Email Login
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox
                                    checked={formData.numberLogin}
                                    onChange={handleNumberChange}
                                >
                                    Number Login
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={5}>
                        <div className="registration-group">
                            <Form.Item>
                                <Checkbox
                                    checked={formData.selfRegistration}
                                    onChange={handleSelfRegistrationChange}
                                >
                                    Self Registration Login
                                </Checkbox>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>


                <div className="create-client-button-container">
                    <Button
                        type="primary"
                        onClick={onFinish}
                        disabled={!isFormValid}
                    >
                        <img src={Arrow} alt="Arrow Icon" className="arrow-circle" />
                        {addMode ? "Add Client" : accountId ? "Edit Client" : "Create New Client"}
                    </Button>
                </div>

                {editMode && (
                    <Paper sx={{ padding: "20px", marginTop: "20px", borderRadius: 2, boxShadow: 3 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h3>Role Management</h3>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpen}
                                sx={{ marginLeft: "auto" }}
                            >
                                Add Roles
                            </Button>
                        </Box>

                        <Dialog open={open} fullWidth
                            maxWidth="md"
                            scroll="paper" >
                            <DialogTitle>Add New Role</DialogTitle>
                            <DialogContent>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Row gutter={16} style={{ marginTop: "16px" }}>
                                        <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}  >
                                            <TextInputField
                                                label="Role Name"
                                                variant="outlined"
                                                fullWidth
                                                name="addRoleRoleName"
                                                value={formData.addRoleRoleName}
                                                onChange={handleInputChange}
                                                validationRegex={validationRegexOnlyAlpha}
                                            />
                                        </Col>
                                        <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                            <TextInputField
                                                label="POC Name"
                                                variant="outlined"
                                                fullWidth
                                                name="addRolePocName"
                                                value={formData.addRolePocName}
                                                onChange={handleInputChange}
                                                validationRegex={validationRegexOnlyAlpha}
                                            />
                                        </Col>
                                        <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                            <TextInputField
                                                label="POC Contact Number"
                                                variant="outlined"
                                                fullWidth
                                                name="addRolePocContactNumber"
                                                value={formData.addRolePocContactNumber}
                                                onChange={handleInputChange}
                                                validationRegex={validationRegexMobileNo}
                                            />
                                        </Col>
                                        <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                            <TextInputField
                                                label="POC Email"
                                                variant="outlined"
                                                fullWidth
                                                name="addRolePocEmail"
                                                value={formData.addRolePocEmail}
                                                onChange={handleInputChange}
                                                validationRegex={emailRegex}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col span={4}>
                                            <Form.Item>
                                                <Checkbox
                                                    checked={formData.lense}
                                                    onChange={(e) => {
                                                        handleNumberLoginChange(e);
                                                        setFormData({ ...formData, lense: e.target.checked });
                                                    }}
                                                >
                                                    Lense
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item>
                                                <Checkbox
                                                    checked={formData.darts}
                                                    onChange={(e) => {
                                                        handleNumberLoginChange(e);
                                                        setFormData({ ...formData, darts: e.target.checked });
                                                    }}
                                                >
                                                    Darts
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item>
                                                <Checkbox
                                                    checked={formData.clan}
                                                    onChange={handleEmailLoginChange}
                                                >
                                                    Clan
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>

                                    </Row>

                                    {formData.darts && (
                                        <Col span={4}>
                                            <Form.Item>
                                                <Checkbox
                                                    checked={formData.dartsForm}
                                                    onChange={handleNumberLoginChange}
                                                >
                                                    Darts Form
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    )}

                                    {formData.darts && (
                                        <>
                                            <Row gutter={16}>

                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%" }}>
                                                    <TextInputField
                                                        label="Target Edit Days"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="kp1TargetDays"
                                                        value={formData.kp1TargetDays}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value) || value === "") { // Allow numbers and decimals
                                                                setFormData({ ...formData, kp1TargetDays: value });
                                                            }
                                                        }}
                                                    // validationRegex={decimalRegex}
                                                    />
                                                </Col>

                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%" }}>
                                                    <TextInputField
                                                        label="Input Edit Days"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="editDays"
                                                        value={formData.editDays}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value) || value === "") { // Allow numbers and decimals
                                                                setFormData({ ...formData, editDays: value });
                                                            }
                                                        }}
                                                    />
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col sm={12} xs={12} md={6} lg={6} style={{ marginRight: "2%", marginBottom: "2%" }}>
                                                    <Form.Item>
                                                        <Checkbox
                                                            checked={formData.mandatoryTimeDistribution}
                                                            onChange={handleMandatoryProjectTime}
                                                        >
                                                            Mandatory Time Distribution
                                                        </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseAddRole} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleSubmit}
                                    color="primary"
                                    disabled={!isRoleManagementFormValid()}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Box mt={3} display="flex" flexWrap="wrap" gap={2}>
                            {roles.length > 0 ? (
                                roles.map((role) => (
                                    <div key={role.id} className="card" style={{ width: "300px", padding: "16px", border: "1px solid #ccc", borderRadius: "8px", boxSizing: "border-box" }}>
                                        <img
                                            src={role.account?.mainLogoUrl || "https://via.placeholder.com/80"}
                                            alt={role.name}
                                            className="card-image"
                                            style={{ width: "80px", height: "80px", objectFit: "cover" }}
                                        />
                                        <h3 className="card-title" style={{ fontSize: "18px", margin: "10px 0" }}>{role.name}</h3>
                                        <button
                                            onClick={() => handleEditClick(role)}
                                            className="card-button"
                                            style={{ padding: "8px 16px", backgroundColor: "#FF8C00", color: "#fff", border: "none", cursor: "pointer" }}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <Typography>No roles found.</Typography>
                            )}

                            {currentRole && (
                                <Dialog open={openRoles} fullwidth maxWidth="xl" scroll="paper">
                                    <DialogTitle>Edit Role Details</DialogTitle>
                                    <DialogContent>
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Row gutter={16} style={{ marginTop: "16px" }}>
                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}  >
                                                    <TextInputField
                                                        label="Role Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={currentRole.name}
                                                        validationRegex={validationRegexOnlyAlpha}
                                                        onChange={(e) => setCurrentRole({ ...currentRole, name: e.target.value })}
                                                    />
                                                </Col>
                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                                    <TextInputField
                                                        label="POC Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={currentRole.pocName || ""}
                                                        validationRegex={validationRegexOnlyAlpha}
                                                        onChange={(e) => setCurrentRole({ ...currentRole, pocName: e.target.value })}
                                                    />
                                                </Col>
                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                                    <TextInputField
                                                        label="Contact Number"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={currentRole.pocContactNumber || ""}
                                                        validationRegex={validationRegexMobileNo}
                                                        onChange={(e) => setCurrentRole({ ...currentRole, pocContactNumber: e.target.value })}
                                                    />
                                                </Col>
                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                                    <TextInputField
                                                        label="Email"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={currentRole.pocEmail || ""}
                                                        validationRegex={emailRegex}
                                                        onChange={(e) => setCurrentRole({ ...currentRole, pocEmail: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={4}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={currentRole.lensNeeded || false}
                                                                onChange={handleCheckboxChange}
                                                                name="lensNeeded"
                                                            >
                                                                Lense
                                                            </Checkbox>
                                                        }
                                                    />
                                                </Col>

                                                <Col span={4}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={currentRole.dartsNeeded || false}
                                                                onChange={handleCheckboxChange}
                                                                name="dartsNeeded"
                                                            >
                                                                Darts
                                                            </Checkbox>

                                                        }
                                                    />
                                                </Col>

                                                <Col span={4}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={currentRole.clanNeeded || false}
                                                                onChange={handleCheckboxChange}
                                                                name="clanNeeded"
                                                                label="Lense"
                                                            >
                                                                Clan
                                                            </Checkbox>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Box>

                                        {currentRole.dartsNeeded && (
                                            <Row>
                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={currentRole.dartsFormNeeded || false}
                                                                onChange={handleCheckboxChange}
                                                                name="dartsFormNeeded"
                                                            >
                                                                Darts Form
                                                            </Checkbox>
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )}

                                        {currentRole.dartsNeeded && (
                                            <Row gutter={16}>
                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                                    <TextInputField
                                                        label="Target Edit Days"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                            currentRole.targetEditDays !== null && currentRole.targetEditDays !== undefined
                                                                ? currentRole.targetEditDays
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value) || value === "") { // Allow numbers and decimals
                                                                setCurrentRole({ ...currentRole, targetEditDays: value });
                                                            }
                                                        }}
                                                    />
                                                </Col>

                                                <Col sm={12} xs={12} md={6} lg={5} style={{ marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                                                    <TextInputField
                                                        label="Input Edit Days"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                            currentRole.inputEditDays !== null && currentRole.inputEditDays !== undefined
                                                                ? currentRole.inputEditDays
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value) || value === "") { // Allow numbers and decimals
                                                                setCurrentRole({ ...currentRole, inputEditDays: value });
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        )}

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={currentRole.mandatoryTimeDistribution || false}
                                                    onChange={handleCheckboxChange}
                                                    name="mandatoryTimeDistribution"
                                                >
                                                    Mandatory Time Distribution
                                                </Checkbox>
                                            }
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseAddRole} color="secondary">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleSave}
                                            color="primary"
                                            disabled={addRoleloading} // Disable the button while loading
                                        >
                                            {addRoleloading ? "Saving..." : "Save"}
                                        </Button>

                                    </DialogActions>
                                </Dialog>
                            )}
                        </Box>
                    </Paper>
                )}
            </div>
        </Content>
    );
};

export default CreateClientForm;