import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store/store';
import Home from "./components/Home";
import LoginPage from "./components/LoginPage";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import { removeToken } from './services/LocalStorageService';
import { setUserToken, unSetUserToken } from './features/authSlice';
import { onError } from '@apollo/client/link/error';
import { unSetUserAccount } from "./features/authSlice";
import { useNavigate } from 'react-router-dom';
import Sidebar from "./sidebar/sidebar";
import { Layout } from "antd";
import ReusableHeader from "./reusableField/ReusableHeader";
import CreateClient from "./components/createClient";
import CreateClientForm from "./components/createClientForm";
import ImportFromLense from "./components/importFromLense";
import DynamicBreadcrumb from './components/dynamicBreadcrumb';
import { BreadcrumbProvider } from "./components/breadcrumbContext";
import ConfirmPassword from "./components/confirmPassword";
import client from "./services/apolloClient";
import JobRoles from "./components/jobRoles";

// Protected Layout Component
const ProtectedLayout = ({ children, isAuthenticated, setIsAuthenticated }) => {
    // const location = useLocation();

    // if (!isAuthenticated) {
    //     return <Navigate to="/login-page" state={{ from: location.pathname + location.search }} replace />;
    // }

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sidebar />
            <div style={{ flex: 1 }}>
                <ReusableHeader setIsAuthenticated={setIsAuthenticated} />
                <DynamicBreadcrumb />
                {children}
            </div>
        </Layout>
    );
};

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { access_token } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Initial authentication check
    useEffect(() => {
        const token = localStorage.getItem("flutter.accessToken");
        if (token) {
            dispatch(setUserToken({ access_token: token }));
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
            if (!location.pathname.includes('login') &&
                !location.pathname.includes('forgot-password') &&
                !location.pathname.includes('reset-password') &&
                !location.pathname.includes('confirmPassword')) {
                navigate('/login-page', { state: { from: location.pathname } });
            }
        }
    }, [dispatch, navigate, location.pathname]);

    // Monitor token changes
    useEffect(() => {
        if (access_token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [access_token]);

    const httpLink = createHttpLink({
        uri: 'https://clanapilightsail.clanonline.in/clantestapi/graphql',
    });

    const errorLink = onError(({ networkError }) => {
        if (networkError && networkError.statusCode === 401) {
            handleLogout();
        }
    });

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem("flutter.accessToken");
        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : "",
            },
        };
    });

    const links = ApolloLink.from([errorLink, authLink, httpLink]);

    // Apollo Client default options
    client.defaultOptions = {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    };

    const handleLogout = () => {
        removeToken();
        dispatch(unSetUserToken({ access_token: null }));
        dispatch(unSetUserAccount({ user_account: null }));
        navigate("/login-page");
    };

    return (
        <BreadcrumbProvider>
            <Provider store={store}>
                <ApolloProvider client={client}>
                    <Routes>
                        {/* Public Routes */}
                        <Route
                            path="/login-page"
                            element={
                                isAuthenticated ? (
                                    <Navigate to={location.state?.from || "/admin"} replace />
                                ) : (
                                    <LoginPage setIsAuthenticated={setIsAuthenticated} />
                                )
                            }
                        />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/confirmPassword" element={<ConfirmPassword />} />

                        {/* Protected Routes */}
                        <Route
                            path="/admin"
                            element={
                                <ProtectedLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
                                    <Home />
                                </ProtectedLayout>
                            }
                        />
                        <Route
                            path="/create"
                            element={
                                <ProtectedLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
                                    <CreateClient />
                                </ProtectedLayout>
                            }
                        />
                        <Route
                            path="/import-from-lense"
                            element={
                                <ProtectedLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
                                    <ImportFromLense />
                                </ProtectedLayout>
                            }
                        />
                        <Route
                            path="/create-client-form"
                            element={
                                <ProtectedLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
                                    <CreateClientForm />
                                </ProtectedLayout>
                            }
                        />
                        <Route
                            path="/job-roles"
                            element={
                                <ProtectedLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
                                    <JobRoles />
                                </ProtectedLayout>
                            }
                        />

                        {/* Root Route */}
                        <Route
                            path="/"
                            element={
                                <Navigate
                                    to={isAuthenticated ? (location.pathname === "/" ? "/admin" : location.pathname) : "/login-page"}
                                    replace
                                />
                            }
                        />
                    </Routes>
                </ApolloProvider>
            </Provider>
        </BreadcrumbProvider>
    );
}

export default App;