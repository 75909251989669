import React, { useState } from "react";
import LoginImage from "../Images/login-image.png";
import icon2 from "../Images/icon2.png";
import EmailInputField from "../reusableField/EmailInputField";
import TextInputField from "../reusableField/TextInputField";
import { Col } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { emailRegex, passwordRegex } from "../regex/regex";
import { BASE_URL } from "./apiConfig";
import "./LoginPage.css";

const LoginPage = ({ setIsAuthenticated }) => {
    const [loginId, setLoginId] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();
    const appKey = "7cb80ffc8df6a5bf488e69f001ee0ab3";
    const validateEmail = () => {
        if (!emailRegex.test(loginId)) {
            setMessage("Email is entered wrong.");
            setMessageType("error");
            return false;
        } else {
            setMessageType("success");
            return true;
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        setMessage("");
        setEmailError("");
        setPasswordError("");

        let valid = true;
        if (!loginId) {
            setEmailError("Please enter your email.");
            valid = false;
        }

        if (!password) {
            setPasswordError("Please enter your password.");
            valid = false;
        }

        if (!valid) return;

        if (!validateEmail()) return;

        setLoginLoading(true);

        try {
            const response = await fetch(`${BASE_URL}/user_validate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: loginId, password, appkey: appKey, session: "12434" }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem("flutter.accessToken", data.access_token);
                setIsAuthenticated(true);
                navigate("/admin");
            } else {
                const errorData = await response.json();
                setMessage(errorData.detail || "Email or Password is incorrect.");
                setMessageType("error");
            }

        } catch (error) {
            setMessage("Error: Unable to log in. Please try again.");
            setMessageType("error");
        } finally {
            setLoginLoading(false);
        }
    };


    // const generateShapes = () => {
    //     const shapes = [];
    //     const shapeTypes = ["square", "triangle", "star"];
    //     for (let i = 0; i < 50; i++) {
    //         const shapeType = shapeTypes[i % shapeTypes.length];
    //         const leftPosition = Math.random() * 100 + "%";
    //         const topPosition = Math.random() * 100 + "%";
    //         const animationDuration = Math.random() * 10 + 10 + "s";
    //         const animationDelay = Math.random() * 5 + "s";

    //         shapes.push(
    //             <div
    //                 key={i}
    //                 className={`shape ${shapeType}`}
    //                 style={{
    //                     left: leftPosition,
    //                     top: topPosition,
    //                     animationDuration: animationDuration,
    //                     animationDelay: animationDelay,
    //                 }}
    //             ></div>
    //         );
    //     }
    //     return shapes;
    // };




    return (
        <div className="background-color">
            {/* {generateShapes()} */}
            <div className="login-container">
                <div className="wrapper-container">
                    <div className="illustration-container">
                        <img src={LoginImage} alt="Illustration" className="illustration" />
                    </div>
                    <div className="form-container">
                        <div className="border">
                            <img src={icon2} alt="icon2" className="icon2" />
                            <p className="welcome-text">Welcome</p>

                            <form className="login-form" onSubmit={handleLogin}>
                                <div className="form-group">
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Login ID</label>
                                    <Col sm={12} xs={12} md={12} lg={12}>
                                        <EmailInputField
                                            name="Email"
                                            placeholder="Enter Login ID "
                                            type="email"
                                            value={loginId}
                                            onChange={(e) => {
                                                setLoginId(e.target.value);
                                                setEmailError("");
                                            }}
                                            feedbackMessage="Please enter Email."
                                            validationRegex={emailRegex}
                                            validationErrorMessage="Please enter a valid Email."
                                        />
                                        {emailError && <p className="emailandpassword-error-message">{emailError}</p>}
                                    </Col>
                                </div>
                                <div className="form-group">
                                    <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Password</label>
                                    <Col sm={12} xs={12} md={12} lg={12}>
                                        <div className="password-field-container">
                                            <TextInputField
                                                placeholder="Enter Password"
                                                name="password"
                                                type={passwordVisible ? "text" : "password"}
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                    setPasswordError("");
                                                }}
                                                feedbackMessage="Please enter your password."
                                                validationRegex={passwordRegex}
                                                // validationErrorMessage="Password is required."
                                            />
                                            {passwordError && <p className="emailandpassword-error-message">{passwordError}</p>}
                                            {password && (
                                                <span
                                                    className="eye-icon"
                                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                                >
                                                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            )}
                                        </div>
                                    </Col>
                                </div>
                                {message && <p className={`backend-error-message ${messageType}`}>{message}</p>}
                                <div className="submit-container">
                                    <button type="submit" className="submit-btn" disabled={loginLoading}>
                                        {loginLoading ? "Loading..." : "Submit"}
                                    </button>
                                </div>
                                <div>
                                    <p className="forget-password">Forget Password?&nbsp;<span onClick={() => navigate("/forgot-password")} className="forget-password-click-here">Click Here</span></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
