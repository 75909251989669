import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import "./emailInputField.css";

const EmailInputField = ({
  name,
  required,
  value,
  type,
  onChange,
  placeholder,
  feedbackMessage,
  validationRegex,
  validationErrorMessage,
  validated,
  maxLength,
}) => {
  const [error, setError] = useState(null);

  const validateEmail = (email) => {
    // Check if email contains both @ and .com
    const hasAtSymbol = email.includes('@');
    const hasDotCom = email.toLowerCase().includes('.');
    return hasAtSymbol && hasDotCom && validationRegex.test(email);
  };

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }

    // Validate email format
    const isValidEmail = validateEmail(inputValue);
    
    if (inputValue.length > 0) {
      if (!isValidEmail) {
        setError("Enter valid e-mail ID ");
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }

    onChange({ target: { name, value: inputValue } });
  };

  // Determine input class based on validation state
  const getInputClassName = () => {
    const baseClass = 'readOnlyDate_field';
    if (!value) return baseClass;
    return `${baseClass} ${validateEmail(value) ? 'valid-border' : ''}`;
  };

  return (
    <Form.Group>
      <Form.Control
        type={type}
        name={name}
        className={getInputClassName()}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={handleInputChange}
        maxLength={maxLength}
        isInvalid={!!error}
      />
      {error && (
        <Form.Control.Feedback type="invalid"  className="email-error-message">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default EmailInputField;