import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  access_token: null,
  user_account: null
}

export const authSlice = createSlice({
  name: 'auth_token',
  initialState,
  reducers: {
    setUserToken: (state, action) => {
        state.access_token = action.payload.access_token;
    },
    unSetUserToken: (state) => {
        state.access_token = null; // Directly setting to null
    },
    setUserAccount: (state, action) => {
      state.user_account = action.payload.user_account;
    },
    unSetUserAccount: (state) => {
      state.user_account = null; // Directly setting to null
    }
  },
});

// Action creators are generated for each case reducer function
export const { setUserToken, unSetUserToken, setUserAccount, unSetUserAccount } = authSlice.actions;

export default authSlice.reducer;
