import React, { useState, useEffect } from "react";
import { Form, Button, message, Layout, Row, Col, Upload, Checkbox, Modal, Input } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { emailRegex, validationRegexAddress, validationRegexMobileNo, validationRegexOnlyAlpha } from "../regex/regex";
import { Account_Form_Query, Role_Account_Query, Get_Lense_Account_Projects, Upload_Document, update_Account, Role_Management, Get_All_Job_Role } from "../services/userAPI"; // Import your mutation
import TextInputField from "../reusableField/TextInputField2";
import "./createClient.css";
import { ChromePicker } from "react-color";
import { useNavigate } from "react-router-dom";
import CreateClientImage from "../Images/createClientImage.png"
import AddRoleIcon from "../Images/Add_Role_icon.png"
import { Radio } from "antd";
import Lessthan from "../Images/lessthan_Icon.png"
import CreateIconPlus from "../Images/createplus_icon.png"
import DeleteButton from "../Images/deleteButton-icon.png"

const { Content } = Layout;

const CreateClient = () => {
    const { id } = useParams();
    const location = useLocation();
    const accountId = id || location.state?.id;

    const { projectsData: passedProjectsData } = location.state || {};

    const [open, setOpen] = useState(false);
    const [uploadedFilename, setUploadedFilename] = useState(null);
    const [color, setColor] = useState("#FFAD01");
    const [showPicker, setShowPicker] = useState(false);
    const [clientImage, setClientImage] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [role, setRole] = useState([]);
    const [form] = Form.useForm();
    const [errors, setErrors] = useState({})
    const [touched, setTouched] = useState({})
    const [formData, setFormData] = useState({
        id: location.state?.id || "",
        name: location.state?.name || "",
        imageUrl: location.state?.imageUrl || "",
        mainLogoUrl: location.state?.mainLogoUrl || "",
        address: location.state?.address || "",
        role: location.state?.role || "",
        companyURL: location.state?.url || "",
        address2: location.state?.address2 || "",
        roles: [],
        selfRegistration: false,
        brandColor: location.state?.brandColor || "#FFAD01",
        addRoleRoleName: "",
        addRolePocName: "",
        addRolePocContactNumber: "",
        addRolePocEmail: "",
        lense: location.state?.lenseNeeded || false,
        darts: location.state?.dartsNeeded || false,
        clan: location.state?.clanNeeded || false,
        mandatoryTimeDistribution: false,
        dartsForm: false,
        kp1TargetDays: "",
        editDays: "",
        filename: "",
        imageUrl: "",
        emailLogin: location.state?.emailLogin || false, // Ensure this gets the correct emailLogin value
        numberLogin: location.state?.numberLogin || false,

    });

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDiscardClick = () => {
        navigate("/admin");
    };

    const [uploadDocument] = useMutation(Upload_Document, {
        onCompleted: (data) => {
            if (data.uploadDocument.success) {
                const filename = data.uploadDocument?.data?.data?.filename;
                const imageUrl = data.uploadDocument?.data?.data?.url;
                setUploadedFilename(filename);

                setFormData((prevData) => ({
                    ...prevData,
                    imageUrl: imageUrl,
                    filename: filename,
                }));
                message.success("Image uploaded successfully!");
            } else {
                message.error("Failed to upload image: " + data.uploadDocument.message);
            }
        },
        onError: (error) => {
            message.error("Upload failed: " + error.message);
        },
    });

    const { data: rolesData, loading: rolesLoading, error: rolesError } = useQuery(Role_Account_Query, {
        variables: { accountId: parseInt(accountId) },
        skip: !accountId,
    });

    const navigate = useNavigate();


    const [addAccount, { error }] = useMutation(Account_Form_Query, {
        onCompleted: (data) => {
            message.success("Client created successfully!");
            navigate("/create");
        },
        onError: (error) => {
            // message.error("Failed to create client: " + error.message);
            message.error("Something went wrong");
        },
    });

    const handleImageChange = ({ file }) => {
        if (!file) {
            message.error("Invalid file object.");
            return;
        }

        const allowedFormats = ["image/jpeg", "image/png"];
        const actualFile = file.originFileObj || file;

        if (!actualFile) {
            message.error("No valid file detected.");
            return;
        }

        if (!allowedFormats.includes(actualFile.type)) {
            message.error("Invalid file format. Only JPEG and PNG are allowed.");
            return;
        }

        try {
            // Create a preview of the image
            const imageUrl = URL.createObjectURL(actualFile);
            setClientImage(imageUrl);

            setErrors((prevErrors) => ({
                ...prevErrors,
                clientImage: "", // Clear error for clientImage
            }));

            // Upload the actual file
            uploadDocument({
                variables: {
                    s3folder: "COMPANY_LOGO",
                    document: actualFile, // Pass the raw file object here
                },
                context: {
                    useMultipart: true, // Ensure the Apollo Client supports multipart file uploads
                },
            });
        } catch (error) {
            console.error("Error handling the file:", error);
            message.error("File upload failed.");
        }
    };

    const { data, loading } = useQuery(Get_Lense_Account_Projects, {
        variables: { clientId: String(accountId) },
        skip: !accountId && !passedProjectsData,
    });

    const projectsData = passedProjectsData || data?.getLenseAccountProjects;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setTouched((prevTouched) => ({
            ...prevTouched,
            [name]: true,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

        setTouched((prevTouched) => ({
            ...prevTouched,
            projectAccess: true,
            [name]: true,
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };

            // If neither darts nor clan is selected, don't require loginMethod
            if ((formData.darts || formData.clan) && !formData.loginMethod) {
                newErrors.loginMethod = "Please select a login method (Mobile or Email) when Darts or Clan is selected.";
            } else if (!formData.darts && !formData.clan && !formData.loginMethod) {
                delete newErrors.loginMethod; // Allow submitting if darts/clan are not selected
            } else {
                delete newErrors.loginMethod; // Clear the loginMethod error
            }

            // Ensure at least one of emailLogin, numberLogin, or selfRegistration is selected
            if (!formData.emailLogin && !formData.numberLogin && !formData.selfRegistration) {
                newErrors.projectAccess = "At least one project access option should be selected.";
            } else {
                delete newErrors.projectAccess;
            }

            return newErrors;
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Enter client's name.";
        }

        if (!formData.companyURL) {
            newErrors.companyURL = " Enter client's URL.";
        }

        if (!formData.address) {
            newErrors.address = " Enter office address.";
        }

        if (!clientImage && !formData.mainLogoUrl) {
            newErrors.clientImage = "Upload image.";
        }

        if (!formData.address2) {
            newErrors.address2 = "Enter office address 2.";
        }

        if ((formData.darts || formData.clan) && !formData.numberLogin && !formData.emailLogin) {
            newErrors.loginMethod = "Select mobile or email-id.";
        }

        if (!formData.emailLogin && !formData.numberLogin && !formData.lense) {
            newErrors.projectAccess = "At least one project access option should be selected.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [formData]);

    const [updateClient] = useMutation(update_Account, {
        onCompleted: (data) => {
            if (data.updateAccount.success) {
                message.success("Client updated successfully!");
                navigate("/create");
            } else {
                message.error(data.updateAccount.message || "Failed to update client");
            }
        },
        onError: (error) => {
            message.error("Something went wrong: " + error.message);
        },
    });

    useEffect(() => {
        return () => {
            if (clientImage) {
                URL.revokeObjectURL(clientImage);
            }
        };
    }, [clientImage]);


    const [addJobrole, { loading: addRoleLoading }] = useMutation(Role_Management, {
        onCompleted: (data) => {
            if (data.addJobrole.success) {
                message.success("Role added successfully!");
                setTimeout(() => {
                    setOpen(false); // Close modal or form dialog
                }, 500);
                resetForm();
            } else {
                message.error(data.addJobrole.message || "Failed to add role");
            }
        },
        onError: (error) => {
            message.error("Something went wrong: " + error.message);
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const newTouched = {
            name: true,
            companyURL: true,
            address: true,
            address2: true,
            projectAccess: true,
            loginMethod: true,
            clientImage: true,
        };

        setTouched(newTouched);

        // Validate form
        if (validateForm()) {
            // Prepare payload for API submission
            const variables = {
                name: formData.name,
                url: formData.companyURL,
                address: formData.address,
                address2: formData.address2,
                brandColor: formData.brandColor,
                lenseNeeded: formData.lense || false,
                dartsNeeded: formData.darts || false,
                clanNeeded: formData.clan || false,
                loginMethod: formData.loginMethod,
                mainLogo: formData.filename, // Use the stored filename from the upload
                smallLogo: formData.filename,
                numberLogin: formData.numberLogin || false,
                emailLogin: formData.emailLogin || false,
                selfRegistration: false, // Always false for self-registration
            };

            // If either numberLogin or emailLogin is selected, include loginMethod based on the selection
            if (formData.numberLogin || formData.emailLogin) {
                variables.loginMethod = formData.numberLogin ? "mobile" : "email";
            }

            // Call the API with the variables object
            addAccount({
                variables,
                onCompleted: () => {
                    // Reset form data, touched, and errors after successful client addition
                    setFormData({
                        name: "",
                        companyURL: "",
                        address: "",
                        address2: "",
                        brandColor: "",
                        lense: false,
                        darts: false,
                        clan: false,
                        loginMethod: "",
                        numberLogin: false,
                        emailLogin: false,
                        selfRegistration: false,
                    });

                    setTouched({});
                    setErrors({});
                    setClientImage(null); // Clear the client image
                    message.success("Client added successfully!");
                    navigate("/admin");
                },
                onError: (error) => {
                    message.error("There was an error adding the client.");
                },
            });
        } else {
            message.error("Please fix the errors before submitting.");
        }
    };

    const resetForm = () => {
        setFormData({
            name: "",
            companyURL: "",
            address: "",
            address2: "",
            emailLogin: false,
            numberLogin: false,
            selfRegistration: false,
            loginMethod: "",
        });
        setClientImage(null);
        setErrors({});
        setTouched({});
    };

    const validationRegexOnlyAlphaAddRole = /^[a-zA-Z\s]{2,30}$/;  // Letters and spaces, 2-30 characters
    const validationRegexMobileNoAddRole = /^[6-9]\d{9}$/;  // Indian mobile number format
    const emailRegexAddRole = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleLoginMethodChange = (e) => {
        const value = e.target.value;

        setFormData((prevFormData) => {
            const newFormData = { ...prevFormData };

            if (value === "mobile") {
                newFormData.numberLogin = true;
                newFormData.emailLogin = false;
            } else if (value === "email") {
                newFormData.numberLogin = false;
                newFormData.emailLogin = true;
            }

            return newFormData;
        });

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors.loginMethod;  // Remove the error for loginMethod
            return newErrors;
        });
    };

    useEffect(() => {
        setRole(rolesData?.getAllJobrole || []);
    }, [rolesData]);

    const AddRoleSubmitButton = () => {
        form.validateFields()
            .then(values => {
                const newRole = { name: values['role name'] };
                setRole(prevRoles => [...prevRoles, newRole]); // Add new role to the state
                form.resetFields();
                setIsModalVisible(false);
            })
            .catch(error => {
                const emptyFields = Object.keys(form.getFieldsValue()).filter(key => !form.getFieldValue(key));
                emptyFields.forEach(field => {
                    form.setFields([
                        {
                            name: field,
                            errors: [`Please enter ${field}!`]
                        }
                    ]);
                });
            });
    };

    const existingRoles = rolesData?.getAllJobrole || [];
    const combinedRoles = [
        ...existingRoles,
        ...role.filter(newRole =>
            !existingRoles.some(existingRole =>
                existingRole?.name === newRole?.name
            )
        )
    ];

    const onFinish = () => {
        let brandColor = formData.brandColor || "#FFAD01";
        brandColor = brandColor.startsWith("#") ? brandColor.slice(1) : brandColor; // Remove '#' if it exists

        // Call the updateAccount mutation for editing
        updateClient({
            variables: {
                accountId: parseInt(accountId),
                name: formData.name,
                url: formData.companyURL,
                address: formData.address,
                address2: formData.address2,
                brandColor: formData.brandColor,
                lenseNeeded: formData.lense || false,
                dartsNeeded: formData.darts || false,
                clanNeeded: formData.clan || false,
                loginMethod: formData.loginMethod,
                mainLogo: formData.filename, // Use the stored filename from the upload
                smallLogo: formData.filename,
                numberLogin: formData.numberLogin || false,
                emailLogin: formData.emailLogin || false,
                selfRegistration: false,
            },
        }).then(() => {
            // Redirect after successful update
            window.location.href = "/admin";
        }).catch(error => {
            console.error("Error updating client:", error);
        });
    };

    return (
        <Content style={{ margin: "16px", marginTop: "0px" }}>
            <div className="create-client-container">

                <div className="createClientHeading">
                    {accountId ? "Edit Client" : "Create Client"}
                </div>

                <div className="createClientHeading2"><span style={{ color: "#C12328" }}>*</span>Each Field Is Mandatory.</div>
                <Form.Item name="clientImage">
                    <div className="upload-container">
                        <Upload
                            name="clientImage"
                            showUploadList={false}
                            customRequest={handleImageChange}
                            accept="image/*"
                        >
                            <div className="image-upload-circle">
                                {clientImage ? (
                                    <img
                                        src={clientImage}
                                        alt="Uploaded"
                                        className="uploaded-image"
                                    />
                                ) : formData.imageUrl ? (
                                    <img
                                        src={formData.imageUrl}
                                        alt="Existing Client"
                                        className="uploaded-image"
                                    />
                                ) : formData.mainLogoUrl ? (
                                    <img
                                        src={formData.mainLogoUrl}
                                        alt="Existing Logo"
                                        className="uploaded-image"
                                    />
                                ) : (
                                    <img
                                        src={CreateClientImage}
                                        alt="Upload Icon"
                                        className="upload-icon"
                                    />
                                )}
                            </div>
                        </Upload>

                        <p className="supported-file-text">
                            File supported: <br /> jpg, jpeg or png
                        </p>
                        {touched.clientImage && errors.clientImage && (
                            <div className="createClientErrors">{errors.clientImage}</div>
                        )}
                    </div>
                </Form.Item>

                <Row className="row-container">

                    <Col sm={12} xs={12} md={20} lg={20} style={{ marginBottom: "1%" }}>
                        <div className="clientNameHeading">Client's Profile</div>
                    </Col>

                    <Col sm={12} xs={23} md={11} lg={11} style={{ marginBottom: "2%", marginRight: "3.6%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client's Name</label>

                        <TextInputField
                            name="name"
                            value={formData.name}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the client name."
                            validationRegex={validationRegexOnlyAlpha}
                            onChange={handleInputChange}
                        />
                        {touched.name && errors.name && (
                            <div className="createClientErrors">{errors.name}</div>
                        )}
                    </Col>

                    <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "0.5%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Client's URL</label>

                        <TextInputField
                            name="companyURL"
                            value={formData.companyURL}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the company URL."
                            // validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />
                        {touched.companyURL && errors.companyURL && (
                            <div className="createClientErrors">{errors.companyURL}</div>
                        )}

                    </Col>

                    <Col sm={12} xs={23} md={11} lg={11} style={{ marginBottom: "2%", marginRight: "3.6%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Office Address</label>

                        <TextInputField
                            name="address"
                            value={formData.address}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the address."
                            validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />
                        {touched.address && errors.address && (
                            <div className="createClientErrors">{errors.address}</div>
                        )}

                    </Col>

                    <Col sm={12} xs={23} md={10} lg={11} style={{ marginBottom: "2%", marginRight: "0.5%" }}>
                        <label style={{ fontSize: "15px", color: "#1C1B1F" }}>Office Address 2</label>

                        <TextInputField
                            name="address2"
                            value={formData.address2}
                            placeholder="Enter Text Value"
                            feedbackMessage="Please input the address 2."
                            validationRegex={validationRegexAddress}
                            onChange={handleInputChange}
                        />
                        {touched.address2 && errors.address2 && (
                            <div className="createClientErrors">{errors.address2}</div>
                        )}
                    </Col>

                    <Col sm={12} xs={22} md={11} lg={11} style={{ marginBottom: "2%", marginRight: "2%" }}>
                        <label className="brand-color-label">Brand Color</label>
                        <div className="brand-color-container">
                            <input
                                type="text"
                                value={formData.brandColor}
                                placeholder="Brand Color"
                                onClick={() => setShowPicker(!showPicker)}
                                readOnly
                                className="brandcolorinputfield"
                            />

                            <div
                                className="color-display-circle"
                                style={{
                                    backgroundColor: (typeof formData.brandColor === "string" && formData.brandColor.startsWith("#"))
                                        ? formData.brandColor
                                        : `#${formData.brandColor || ''}`,
                                }}
                            ></div>

                            {showPicker && (
                                <div className="color-picker-container">
                                    <ChromePicker
                                        className="custom-chrome-picker"
                                        color={(typeof formData.brandColor === "string" && formData.brandColor.startsWith("#"))
                                            ? formData.brandColor
                                            : `#${formData.brandColor || ''}`}
                                        onChange={(selectedColor) => {
                                            const newColor = selectedColor.hex;
                                            setColor(newColor);
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                brandColor: newColor,
                                            }));
                                        }}
                                    />
                                    <button
                                        onClick={() => setShowPicker(false)}
                                        className="close-picker-button"
                                    >
                                        Close
                                    </button>
                                </div>
                            )}

                            {showPicker && (
                                <div
                                    onClick={() => setShowPicker(false)}
                                    className="overlay"
                                ></div>
                            )}
                        </div>
                    </Col>
                </Row>

                <div className="row-container">
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} style={{ marginBottom: "1%", marginRight: "-5%" }}>
                            <div className="clientNameHeading">Project Access</div>
                        </Col>
                    </Row>

                    <Row>

                        <Col sm={12} xs={10} md={6} lg={6} style={{ marginRight: "-3%", }}>
                            <Form.Item>
                                <Checkbox
                                    checked={formData.lense}
                                    onChange={handleCheckboxChange}
                                    className="custom-checkbox"
                                    name="lense"
                                >
                                    Lense
                                </Checkbox>
                            </Form.Item>
                        </Col>

                        <Col sm={12} xs={10} md={6} lg={6} style={{ marginLeft: "-13%" }}>
                            <Form.Item>
                                <Checkbox
                                    checked={formData.darts}
                                    name="darts"
                                    onChange={handleCheckboxChange}
                                    style={{ color: formData.emailLogin ? 'black' : '' }}
                                >
                                    Darts
                                </Checkbox>
                            </Form.Item>
                        </Col>

                        <Col sm={12} xs={10} md={6} lg={12} style={{ marginLeft: "-16%" }}>
                            <div>
                                <Form.Item>
                                    <Checkbox
                                        checked={formData.clan}
                                        onChange={handleCheckboxChange}
                                        className="custom-checkbox"
                                        name="clan"
                                    >
                                        CLAN
                                    </Checkbox>
                                </Form.Item>

                            </div>
                        </Col>

                    </Row>
                    {touched.projectAccess && errors.projectAccess && (
                        <div className="createClientErrors">{errors.projectAccess}</div>
                    )}

                    {(formData.darts || formData.clan) && (
                        <Row>
                            {/* <Col sm={12} xs={12} md={6} lg={6} style={{ marginBottom: "-2%", marginLeft: "0%", display: "flex", alignItems: "center" }}> */}
                            <label style={{ fontSize: "15px", color: "#1C1B1F", marginRight: "10px", marginTop: "0.4%" }}>
                                Login Through:
                            </label>
                            <Form.Item
                            >
                                <Radio.Group
                                    onChange={handleLoginMethodChange}
                                    value={formData.numberLogin ? "mobile" : formData.emailLogin ? "email" : ""}
                                >
                                    <Radio value="mobile" className="custom-radio">Mobile</Radio>
                                    <Radio value="email" className="custom-radio">Email ID</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {/* </Col> */}
                        </Row>
                    )}
                    {touched.loginMethod && errors.loginMethod && (
                        <div className="createClientErrors">{errors.loginMethod}</div>
                    )}
                </div>

                {/* {accountId && (
                    <div className="row-container">
                        <div className="clientNameHeading">Role Management</div>
                        <div className="role-cards-container">
                            {combinedRoles.map((roleItem, index) => (
                                roleItem?.name && (
                                    <div key={`role-${index}`} className="role-card">
                                        <div className="role-header">
                                            <div>
                                                <div className="role-label">Role</div>
                                                <div className="role-title">{roleItem.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}

                            <img
                                src={AddRoleIcon}
                                alt="Uploaded"
                                className="AddRoleImage"
                                onClick={showModal}
                            />
                        </div>

                        <Modal
                            width={352}
                            visible={isModalVisible}
                            onCancel={handleCancel}
                            footer={[
                                <div key="footer" className="modal-footer">
                                    <Button key="cancel" onClick={handleCancel} className="cancel-button">
                                        Cancel
                                    </Button>
                                    <Button key="create" onClick={AddRoleSubmitButton} className="create-button">
                                        Create
                                    </Button>
                                </div>,
                            ]}
                        >
                            <Form form={form} layout="vertical">
                                <Form.Item
                                    label="Role Name"
                                    name="role name"
                                    rules={[
                                        { required: true, message: 'Enter a role name!' },
                                        { pattern: validationRegexOnlyAlphaAddRole, message: 'Enter valid role name!' },
                                    ]}
                                >
                                    <Input placeholder="Enter Role Name" />
                                </Form.Item>

                                <Form.Item
                                    label="POC Name"
                                    name="POC name"
                                    rules={[
                                        { required: true, message: 'Enter a POC Name!' },
                                        { pattern: validationRegexOnlyAlphaAddRole, message: 'Enter valid POC name' },
                                    ]}
                                >
                                    <Input placeholder="Enter POC Name" />
                                </Form.Item>

                                <Form.Item
                                    label="Contact No."
                                    name="contact no"
                                    rules={[
                                        { required: true, message: 'Enter a Contact Number!' },
                                        { pattern: validationRegexMobileNoAddRole, message: 'Enter valid mobile number!' },
                                    ]}
                                >
                                    <Input placeholder="Enter Contact Number" />
                                </Form.Item>

                                <Form.Item
                                    label="Email-ID"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Enter an Email-ID!' },
                                        { pattern: emailRegexAddRole, message: 'Enter valid Email-ID!' },
                                    ]}
                                >
                                    <Input placeholder="Enter Email-ID" />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                )} */}


                {/* {accountId && (
                    <>
                        <div
                            className="row-container"
                            style={{
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ fontWeight: "600" }}>Delete Client Data</div>
                            <button className="deleteButton">
                                <img src={DeleteButton} alt="Delete Icon" className="deleteIcon" />
                                Delete
                            </button>
                        </div>
                    </>
                )} */}

                {accountId ? (
                    <div className="accountId-create-page-buttons">
                        <button className="discard-button" onClick={handleDiscardClick}>
                            <img src={Lessthan} alt="Less Than" className="lessthan" />
                            Back
                        </button>
                        <button className="delete-button" onClick={onFinish}>
                            <img src={CreateIconPlus} alt="Less Than" className="CreateIcon" />
                            Save
                        </button>
                    </div>
                ) : null}

                {!accountId ? (
                    <div className="create-page-buttons">

                        <button className="discard-button" onClick={handleDiscardClick}>
                            <img src={Lessthan} alt="Less Than" className="lessthan" />
                            Discard
                        </button>
                        <button className="delete-button" onClick={handleSubmit}>
                            <img src={CreateIconPlus} alt="Less Than" className="CreateIcon" />
                            Create
                        </button>
                    </div>
                ) : null}

            </div>
        </Content >
    );
};

export default CreateClient;