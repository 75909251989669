import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./forgotPage.css";
import { BASE_URL } from "./apiConfig";

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Retrieve the email from the route state
    const [storedEmail] = useState(location.state?.email || "");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleResendEmail = async () => {
        if (!storedEmail) {
            setErrorMessage("No email to resend. Please enter an email first.");
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/forgot_password_email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: storedEmail }),
            });

            if (response.ok) {
                alert("Password reset email has been resent.");
                setErrorMessage("");
            } else {
                setErrorMessage("Failed to resend email. Please try again later.");
            }
        } catch (error) {
            console.error("Error resending email:", error);
            setErrorMessage("Unable to resend email. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenEmailApp = () => {
        window.location.href = "mailto:"; // Opens the email application
    };

    const handleBackToLogin = () => {
        navigate("/");
    };

    return (
        <div className="reset-password-container">
            <div className="reset-password-form">
                <h2 className="reset-password-title">Check your email</h2>
                <p className="reset-password-subtitle">
                    We have sent a password reset link to {storedEmail}.
                </p>
                <button className="security-made-simple" onClick={handleOpenEmailApp}>
                    Open Email App
                </button>
                <p className="reset-password-subtitle">
                    Didn't receive the email?{" "}
                    <button
                        className="resend-link"
                        onClick={handleResendEmail}
                        disabled={isLoading}
                    >
                        {isLoading ? "Resending..." : "Resend"}
                    </button>
                </p>
                <p className="reset-password-subtitle">
                    <button className="back-to-login" onClick={handleBackToLogin}>
                        Back to Login
                    </button>
                </p>
                {errorMessage && (
                    <p className="error-message" style={{ color: "red", marginTop: "10px" }}>
                        {errorMessage}
                    </p>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;
