import React, { useState, useEffect } from "react";
import "./confirmPassword.css";
import logo from "../Images/icon2.png";
import { useLocation } from 'react-router-dom';
import Arrowleft from "../Images/Arrow left.png"
import TextIcon from "../Images/confirmpasswordtexticon.png"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Input, Button, Form } from "antd";

const ConfirmPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [tid, setTid] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const transactionId = urlParams.get("tid");

        if (!transactionId) {
            setErrorMessage("Transaction ID is missing. Please check your URL.");
        } else {
            setTid(transactionId);
            setErrorMessage("");
        }
    }, []);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (confirmPassword && e.target.value !== confirmPassword) {
            setErrorMessage("Passwords do not match! Please try again.");
        } else {
            setErrorMessage("");
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (password && e.target.value !== password) {
            setErrorMessage("Passwords do not match! Please try again.");
        } else {
            setErrorMessage("");
        }
    };

    useEffect(() => {
        if (password && confirmPassword && password !== confirmPassword) {
            setErrorMessage("Passwords do not match! Please try again.");
        } else {
            setErrorMessage("");
        }
    }, [password, confirmPassword]);

    const isDisabled = password === "" || confirmPassword === "" || password !== confirmPassword;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match! Please try again.");
            return;
        }
        setErrorMessage("");
        const payload = {
            token: tid,
            password: confirmPassword,
        };

        setIsLoading(true);

        try {
            const response = await fetch("https://clanapilightsail.clanonline.in/clantestapi/reset_password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage("Your password has been successfully reset.");
                setPassword("");
                setConfirmPassword("");
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.detail || "Something went wrong. Please try again.");
            }
        } catch (error) {
            console.error("Error during password reset:", error);
            setErrorMessage("Unable to process your request. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    if (tid === "") {
        return (
            <div className="no-tid-container">
                <p className="no-tid-message">Please check your URL.</p>
            </div>
        );
    }

    return (
        <div className="confirmPasswordContainer">
            <div className="set-password-container">
                <div style={{ border: "1px solid #c8c8c8", padding: "20px", borderRadius: "20px" }}>
                    <img className="logo" src={logo} />
                    <span className="title-container">
                        {/* <img src={Arrowleft} alt="Arrow Left" className="arrow-icon" /> */}
                        <div className="reset-password-title">Reset Password</div>
                    </span>

                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className="input-group">
                            <label className="email-label">Enter Password</label>
                            <Input.Password
                                className="confirmPasswordInput"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                value={password}
                                onChange={handlePasswordChange}
                            />

                            <span className="confirm-password-text">
                                <img src={TextIcon} alt="Arrow Left" className="confirm-password-icon" />
                                <span style={{ fontSize: "10px", textAlign: "left", display: "block" }}>Enter minimum 8-digit password, which must contain Uppercase, lowercase, digits and special character. </span>
                            </span>
                        </div>
                        <div className="input-group">
                            <label className="email-label" >Re-enter Password</label>
                            <Input.Password
                                className="confirmPasswordInput"
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                            />

                        </div>
                        <div className="button-container">
                            <button
                                type="submit"
                                className="continue-btn"
                                disabled={isDisabled || isLoading} // Disable button when loading or fields are invalid
                            >
                                {isLoading ? "Setting Password..." : "Reset Password"}
                            </button>
                        </div>
                        {errorMessage && <p className="confirm-password-error-message">{errorMessage}</p>}
                        {successMessage && <p className="confirm-password-success-message">{successMessage}</p>}
                    </form>
                </div>
            </div >
        </div >
    );
};

export default ConfirmPassword;
