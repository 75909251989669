import React, { createContext, useState, useContext, useCallback, useMemo } from "react";

const BreadcrumbContext = createContext();

export const BreadcrumbProvider = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const resetBreadcrumb = useCallback(() => {
        setBreadcrumbs((prev) => [...prev]);
    }, []);

    const appendBreadcrumb = useCallback((breadcrumb) => {
        setBreadcrumbs((prev) => {
            // Check if breadcrumb already exists
            const existingIndex = prev.findIndex(b => b.path === breadcrumb.path);
            
            if (existingIndex !== -1) {
                // If exists, update the existing breadcrumb
                const updatedBreadcrumbs = [...prev];
                updatedBreadcrumbs[existingIndex] = breadcrumb;
                return updatedBreadcrumbs;
            }
            
            // If not exists, add new breadcrumb
            return [...prev, breadcrumb];
        });
    }, []);

    const setBreadcrumb = useCallback((newBreadcrumbs) => {
        setBreadcrumbs(newBreadcrumbs);
    }, []);

    const contextValue = useMemo(() => ({
        breadcrumbs,
        resetBreadcrumb,
        appendBreadcrumb,
        setBreadcrumb
    }), [breadcrumbs, resetBreadcrumb, appendBreadcrumb, setBreadcrumb]);

    return (
        <BreadcrumbContext.Provider value={contextValue}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

export const useBreadcrumb = () => {
    const context = useContext(BreadcrumbContext);
    if (!context) {
        throw new Error("useBreadcrumb must be used within a BreadcrumbProvider");
    }
    return context;
};