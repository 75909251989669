import React, { useEffect, useState, useRef } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import Home from "../Images/home-image.png";
import CreateFolder from "../Images/create_new_folder_image.png";
import { useBreadcrumb } from "../components/breadcrumbContext";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    const [isHovered, setIsHovered] = useState(false);
    const location = useLocation();
    const { resetBreadcrumb } = useBreadcrumb();
    const navigate = useNavigate();
    const [selectedKey, setSelectedKey] = useState('1');

    useEffect(() => {
        const menuKeyMap = {
            "^/admin": "1",
            "^/create": "2",
        };

        for (const [pattern, key] of Object.entries(menuKeyMap)) {
            if (location.pathname.match(pattern)) {
                setSelectedKey(key);
                break;
            }
        }
    }, [location.pathname]);

    const sidebarKey = location.pathname;

    // Hide sidebar on create page
    if (location.pathname === '/create' || location.pathname === '/create-client-form') {
        return null;
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMenuClick = (path) => {
        resetBreadcrumb();
        setIsHovered(false);
        navigate(path);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div
                key={sidebarKey}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    height: "85vh",
                    position: "fixed",
                    left: 0,
                    border: "none",
                    zIndex: 1,
                    top: "15%",
                    width: isHovered ? "160px" : "80px",
                    transition: "width 0.5s ease-in-out",
                }}
                className={`sider ${isHovered ? 'expanded' : 'collapsed'}`}
            >
                <Menu
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    style={{
                        border: "none",
                        background: "none",
                        top: "2%",
                    }}
                >
                    <Menu.Item
                        key="1"
                        onClick={() => handleMenuClick('/admin')}
                        icon={
                            <img
                                src={Home}
                                alt="Home"
                                style={{
                                    width: "20px",
                                    height: "20px",
                                }}
                            />
                        }
                    >
                        <Link to="/admin" style={{
                            fontWeight: selectedKey === "1" ? "bold" : "normal",
                            color: "#000",
                            opacity: isHovered ? 1 : 0,
                        }}>
                            Admin
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="2"
                        onClick={() => handleMenuClick('/create')}
                        icon={
                            <img
                                src={CreateFolder}
                                alt="Create"
                                style={{
                                    width: "20px",
                                    height: "20px",
                                }}
                            />
                        }
                    >
                        <Link to="/create" style={{
                            fontWeight: selectedKey === "2" ? "bold" : "normal",
                            color: "#000",
                            opacity: isHovered ? 1 : 0,
                        }}>
                            Create
                        </Link>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
};

export default Sidebar;
