import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBreadcrumb } from "./breadcrumbContext";
import { Breadcrumb } from "antd";

const formatBreadcrumbName = (name) => {
    return name
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const DynamicBreadcrumb = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { breadcrumbs, appendBreadcrumb, setBreadcrumb } = useBreadcrumb();
    const pathnames = location.pathname.split('/').filter(Boolean);

    useEffect(() => {
        const currentPath = `/${pathnames.join('/')}`;

        // Define paths that should reset breadcrumbs
        const resetPaths = ['/admin', '/create', '/confirmPassword'];

        // Check if the current path is in reset paths or is root
        if (resetPaths.includes(currentPath)) {
            setBreadcrumb([]); // Clear breadcrumbs
        }

        // Always append the current path's breadcrumb
        if (pathnames.length > 0) {
            const breadcrumbName = formatBreadcrumbName(
                pathnames[pathnames.length - 1] || "Admin"
            );

            // Prevent duplicate breadcrumbs
            appendBreadcrumb({
                name: breadcrumbName,
                path: currentPath
            });
        }
    }, [location.pathname, appendBreadcrumb, setBreadcrumb]);

    const handleBreadcrumbClick = (path) => {
        navigate(path);
    };

    return (
        <Breadcrumb style={{ marginTop: '9%', marginLeft: '20px' }}>
            {/* {breadcrumbs.map((crumb, index) => {
                const isCurrentPath = crumb.path === location.pathname;

                return (
                    <Breadcrumb.Item
                        key={crumb.path}
                        style={{
                            color: isCurrentPath ? "#4CAF50" : "#000",
                            fontWeight: isCurrentPath ? "bold" : "normal",
                            fontSize: isCurrentPath ? "16px" : "14px",
                        }}
                        onClick={() => !isCurrentPath && handleBreadcrumbClick(crumb.path)}
                    >
                        {isCurrentPath ? (
                            crumb.name
                        ) : (
                            <Link to={crumb.path}>{crumb.name}</Link>
                        )}
                    </Breadcrumb.Item>
                );
            })} */}
        </Breadcrumb>
    );
};

export default DynamicBreadcrumb;