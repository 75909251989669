import { gql } from "@apollo/client";

export const Users_Query = gql`      
query getUsersForAccount($roleId: Int!, $filterByName: String, $pagingCriteria: PagingCriteria) {
    getUsersForAccount(roleId: $roleId, filterByName: $filterByName, pagingCriteria: $pagingCriteria) {
        data {
            id
            name
            profilePicture
            designation
            employeeId
            title
            role
            roleId
            ppm
            gender
            userCharacter
            numberOfAssociates
            numberOfManagers
        }
        pageMeta {
            nextPage
            previousPage
            hasNextPage
            hasPreviousPage
            totalCount
        }
    }
}`;

export const currentUserProfileQuery = gql`
  query ReadCurrentUserProfile{
    currentUserProfile {
      name
      surname 
      designation
    profilePicture
    }
  }
`;


export const User_Form_Query = gql`
    mutation addUser($accountId: Int!,$currentRoleId:Int!, $employeeId: String!,  $firstName: String!,$lastName: String!, 
      $gender: String!,$dateOfBirth:Date!, $email: String!, $higestEducationQualification: String!,$previousEmployer:String!,$previousExperience: String!,
       $phoneNumber: String!, $employementType: String!, $joiningDate: Date!,  $designation: Int!, $branch: String!, $zone: String!, $city: String!, $state: String!, $managerEmployeeId: String!, $validFrom: String!){
         addUser(accountId:$accountId, currentRoleId: $currentRoleId, employeeId: $employeeId, firstName: $firstName, lastName: $lastName,
          gender: $gender, dateOfBirth: $dateOfBirth, email: $email, higestEducationQualification: $higestEducationQualification,previousEmployer: $previousEmployer,
          phoneNumber: $phoneNumber, employementType: $employementType, joiningDate: $joiningDate, designation: $designation, branch: $branch,
          zone: $zone, city: $city, state: $state, managerEmployeeId: $managerEmployeeId, validFrom: $validFrom) {
          data {
            id
            
          }
          success
          message
        }
      }
    `;


export const User_Profile_Query = gql`
query  userProfile($userId:Int!){
  userProfile(userId:$userId){
    id
    accountId
    name
    surname
    designation
    joiningDate
    email
    phoneNumber
    gender
    location
  	address
    role
    employeeId
    roleId
    designationId
    higestEducationQualification
    previousExperience
    previousEmployer
    state
    city
    branch
    zone
    dateOfBirth
    employeeType
    reporting{
        name
        employeeId
    }
    reportingDate
  }
}
`;

export const Accounts_Query = gql`
      {
        allUserAccount{
          id
          name
          address
          url
          mainLogoUrl
          smallLogoUrl
          criticalSubtaskBg
          brandColor
          emailLogin
          numberLogin
          selfRegistration
          address2
          numberOfRoles
          clanNeeded
          dartsNeeded
          lenseNeeded
          numberLogin
          emailLogin
      }  
      }
    `;

export const Account_Form_Query = gql`
    mutation addAccount($name: String!, $address: String!, $address2: String!, $selfRegistration:Boolean!, $url: String!, $mainLogo: String!, $smallLogo: String!, $emailLogin: Boolean!, $numberLogin: Boolean!, $brandColor:String!, $clanNeeded: Boolean!, $dartsNeeded: Boolean!, $lenseNeeded: Boolean! ){
      addAccount(name:$name, address:$address, address2:$address2, selfRegistration:$selfRegistration, url:$url, mainLogo: $mainLogo, smallLogo: $smallLogo, emailLogin: $emailLogin, numberLogin: $numberLogin, brandColor: $brandColor, dartsNeeded:$dartsNeeded, clanNeeded:$clanNeeded, lenseNeeded:$lenseNeeded  ) {
          data {
            id
            
          }
          success
          message
        }
      }
    `;

export const Designations_Query = gql`
query getDesignations($accountId:Int!){
      getDesignations(accountId:$accountId){
        id
        accountId
        jobroleId
        designation
        ppm
        status
    }  
    }
  `;
// ($accountId:Int!)
// (accountId:$accountId)
export const Role_Account_Query = gql`   
    query getAllJobrole($accountId:Int!){
    getAllJobrole(accountId:$accountId){
      id
      name
      clanNeeded
        dartsFormNeeded
        dartsNeeded
      account{
        id
        name
    }
  }
  }  
`;

export const User_Update_Query = gql`
    mutation updateUserProfile($accountId: Int!, $name: String!, $email: String!, $phoneNumber: String!, $employeeId: String!, $location: String!, $gender: String!, $designation: Int!, $address: String!, $currentRoleId: Int!, $joiningDate:Date!){
      updateUserProfile(accountId:$accountId, name:$name,  email:$email, phoneNumber:$phoneNumber, employeeId:$employeeId, location:$location, gender:$gender,designation:$designation, address:$address, currentRoleId:$currentRoleId, joiningDate:$joiningDate) {
          data {
            id
            name
            joiningDate
            gender
          }
          success
          message
        }
      }
    `;

export const List_Contents_Query = gql`
query listVideos($roleId:Int!){
  listVideos(roleId:$roleId){
      id
      title
      description
      vimeoId
      fourmatCategory
      activityName
      taskName
      subtaskName
  }
}
  `;

export const Content_Form_Query = gql`
  mutation addContent($accountId: Int!,$roleId: Int!, $subtaskId: Int!, $title: String!, $code: String!, $description: String!, $thumbnailUrl: String!, $lengthMins: String!, $category: String!,  $userId:Int!, $languageId:Int!){
    addContent(accountId:$accountId, roleId:$roleId, subtaskId:$subtaskId,  title:$title, code:$code, description:$description, thumbnailUrl:$thumbnailUrl,lengthMins:$lengthMins, category:$category, userId:$userId, languageId:$languageId) {
        data {
          id
          
        }
        success
        message
      }
    }
  `;
export const Role_Activities_Query = gql`   
  query getActivitiesByRole($jobrole: Int!){
    getActivitiesByRole(jobrole:$jobrole){
    id
    name
    jobrole
    taskCount
    rankValue
}
}  
`;

export const Get_Task_Activity_Query = gql`   
  query getTaskByActivity($activityId:Int!, $roleId: Int!){
    getTaskByActivity(activityId:$activityId, roleId: $roleId){
    id
    name
    subtaskCount
    activityRank
    rankValue
}
}  
`;

export const Get_Subtask_Task_Query = gql`   
  query getSubtaskByTask($taskId:Int!, $roleId:Int!){
    getSubtaskByTask(taskId:$taskId, roleId:$roleId ){
    id
    name
    videoCount
    isCritical
}
}  
`;
// ($accountId:Int!)
// (accountId:$accountId)
export const Non_Beta_list_Query = gql`   
    query getNotExpert($accountId: Int!){
      getNotExpert(accountId:$accountId){
      id
      name
  }
  }  
`;

export const Beta_Form_Query = gql`
    mutation updateExpert($userId:Int!,$profileSummary: String!, $introVideoCode: String!, $introVideoThumb: String!, $phoneNumber: String!){
      updateExpert(userId:$userId,profileSummary:$profileSummary, introVideoCode:$introVideoCode, introVideoThumb:$introVideoThumb, phoneNumber:$phoneNumber) {
          data {
            introVideoThumb
            introVideoCode
            phoneNumber
            id
            name            
          }
          success
          message
        }
      }
    `;
// ($accountId:Int!)
// (accountId:$accountId)
export const BETA_LIST_Query = gql`   
    query getAllExpert($roleId:Int!){
      getAllExpert(roleId:$roleId){
        id
        name
        phoneNumber
        designation
        role
  }
  }  
`;


export const Beta_Data_Query = gql`   
    query getExpertProfile($userId:Int!){
      getExpertProfile(userId:$userId){
      id
      name
      introVideoThumb
      introVideoCode
      profileSummary
      phoneNumber
      accountId
  }
  }  
`;

export const List_Notifications_Query = gql`
    {   
      getWebadminNotification{
        id
        title
        description
        targetUserId
        createdAt
        eventType
    }
}
  `;
// $userId: Int!, $title: String!, $description: String!
// userId:$userId, title:$title, description:$description
export const Notification_Form_Query = gql`
  mutation createNewWebNotification($data: [Bulknotifications!]!){
    createNewWebNotification(data:$data) {
        data {
          id
          notificationRead
        }
        success
        message
      }
    }
  `;

export const Add_JobRole_Query = gql`
    mutation addJobrole($name: String!, $accountId: Int!){
      addJobrole(name:$name, accountId:$accountId) {
          data {
            id
            name
          }
          success
          message
        }
      }
    `;

export const Add_Activity_Query = gql`
    mutation addActivity($name: String!, $jobroleId: Int!, $afterActivityId:Int!){
      addActivity(name:$name, jobroleId:$jobroleId, afterActivityId:$afterActivityId) {
          data {
            id
            name
          }
          success
          message
        }
      }
    `;
export const Add_Task_Query = gql`
    mutation addTasks($name: String!, $activityId: Int!, $afterTaskId:Int!){
      addTasks(name:$name, activityId:$activityId, afterTaskId:$afterTaskId) {
          data {
            id
            name
          }
          success
          message
        }
      }
    `;

export const Add_SubTask_Query = gql`
    mutation addSubtasks($name: String!, $taskId: Int!, $isCritical: Boolean!, $nonCriticalDescription: String!, $rankForRecommendation: Int!, $afterSubTaskId: Int!){
      addSubtasks(name:$name, taskId:$taskId, isCritical:$isCritical, nonCriticalDescription:$nonCriticalDescription, rankForRecommendation: $rankForRecommendation, afterSubTaskId:$afterSubTaskId) {
          data {
            id
            name
          }
          success
          message
        }
      }
    `;
// [{$empId: String!, $monthDetails: Date!, $performance: String!}]
// [{empId:$empId, monthDetails:$monthDetails, performance:$performance
export const Create_Performance_Query = gql`    
    mutation createPerformanceCapture($data: [Performancecaptureparameters!]!){
      createPerformanceCapture(data:$data)  {
          data {
            accountId
            empId
          }
          success
          message
        }
      }
    `;
export const User_Bulk_Upload_Query = gql`    
    mutation addMutlipleUsers($data: [AddUser!]!){
      addMutlipleUsers(data:$data){
          data {
            data
            message
          }
          
          message
          success
        }
      }
    `;

export const Update_User_Bulk_Upload_Query = gql`    
    mutation updateMutlipleUsers($data: [AddUser!]!){
      updateMutlipleUsers(data:$data){
          data {
            data
            message
          }
          
          message
          success
        }
      }
    `;
export const List_Languages_Query = gql`
    {   
      mdLanguages{
        id
        code
        name
    }
}
  `;
export const SubTask_Bulk_Upload_Query = gql`    
  mutation addMultipleSubTasks($data: [AddActivityTaskSubTask!]!){
    addMultipleSubTasks(data:$data){
        data {
          data
          message
        }
        
        message
        success
      }
    }
  `;

export const User_Account_Query = gql`
      {
        currentUserAccount{
       id
       name
       mainLogoUrl
       smallLogoUrl
   }
      }
    `;
// input PerformanceInput{
//   empId: String!
//   monthDetails: String!
//   performance: String!
// }

export const jobroles_count_Query = gql`
query getAdminDashboard($accountId:Int!){
  getAdminDashboard(accountId:$accountId){
    roleName
    numberOfMembers
    }  
    }
  `;

export const Current_User_Profile_Query = gql`
  {
    currentUserProfile{
   id
   name
   roleId
}
  }
`;

export const Funnel_Query = gql`
query getKpiRatios($roleId:Int!){
  getKpiRatios(roleId:$roleId){
      imageUrl
      data{
          id
          kpiId
          kpiName
          kpiVersion
          relationKpiId
          relationKpiName
          relationPer
      }
  }
}
    `;

export const Create_Funnel_Query = gql`    
    mutation kpiRelationChange($data: [KpiRelationChange!]!){
      kpiRelationChange(data:$data)  {
          data {            
            kpiId,
            relationPer,
            kpiVersion
          }
          success
          message
        }
      }
    `;

export const State_Query = gql`
    {
      getStates{
     id
     stateName
  }
    }
  `;

export const City_Query = gql`
  query getCity($stateId:Int!){
    getCity(stateId:$stateId){
      id
      cityName
      }  
      }
  `;

export const Verify_EMPID_Query = gql`
  query verifyEmployeeId($employeeId:String!){
    verifyEmployeeId(employeeId:$employeeId){
      id
      isExpert
      employeeId
      name
      }  
      }
  `;

export const Get_Lense_Account = gql`
  query GetLenseAccount{
    getLenseAccount{
        id
        name
        address
        image
        imageUrl
        role
    }
}`

export const Get_Lense_Account_Projects = gql`
query GetLenseAccountProjects($clientId:String!){
  getLenseAccountProjects(clientId:$clientId){
      id
      name
      __typename
  }
}`

export const Upload_Document = gql`
mutation uploadDocument ($s3folder: S3folder!, $document: Upload!) {
  uploadDocument (s3folder: $s3folder, document: $document) {
      success
      message
      data {
          data
      }
  }
}`

export const update_Account = gql`
  mutation updateAccount(
    $accountId: Int!,
    $name: String!,
    $address: String!,
    $address2: String!,
    $url: String!,
    $mainLogo: String!,
    $smallLogo: String!,
    $emailLogin: Boolean!,
    $numberLogin: Boolean!,
    $brandColor: String!
    $selfRegistration : Boolean!
  ) {
    updateAccount(
      accountId: $accountId,
      name: $name,
      address: $address,
      address2: $address2,
      url: $url,
      mainLogo: $mainLogo,
      smallLogo: $smallLogo,
      emailLogin: $emailLogin,
      numberLogin: $numberLogin,
      brandColor: $brandColor,
      selfRegistration : $selfRegistration,
    ) {
      success
      message
      data {
        id
        name
        address
        url
        brandColor
        criticalSubtaskBg
        mainLogoUrl
        smallLogoUrl
        emailLogin
        numberLogin
        availableLanguages {
          code
        }
        lenseId
      }
    }
  }
`;

export const Role_Management = gql`
    mutation AddJobRole(
        $accountId: Int!
        $name: String!
        $clanNeeded: Boolean!
        $dartsNeeded: Boolean!
        $dartsFormNeeded: Boolean!
        $jobroleId: Int
        $pocName: String!
        $pocEmail: String!
        $pocNumber: String!
        $mandatoryTimeDistribution: Boolean!
        $targetEditDays: Int!
        $inputEditDays: Int!
    ) {
        addJobrole(
            accountId: $accountId
            name: $name
            clanNeeded: $clanNeeded
            dartsNeeded: $dartsNeeded
            dartsFormNeeded: $dartsFormNeeded
            jobroleId: $jobroleId
            pocName: $pocName
            pocEmail: $pocEmail
            pocNumber: $pocNumber
            mandatoryTimeDistribution: $mandatoryTimeDistribution
            targetEditDays: $targetEditDays
            inputEditDays: $inputEditDays
        ) {
            success
            message
            data {
                id
                name
                account {
                    id
                }
                clanNeeded
                dartsNeeded
                dartsFormNeeded
            }
        }
    }
`;

export const Get_All_Job_Role = gql`
query GetAllJobrole($accountId: Int) {
  getAllJobrole(accountId: $accountId) {
   id
    name
    account {
      id
      name
      address
      url
      brandColor
      criticalSubtaskBg
      mainLogoUrl
      smallLogoUrl
      emailLogin
      numberLogin
      availableLanguages{
        code
      }
      lenseId
      selfRegistration
      address2
    }
    targetEditDays
    inputEditDays
    pocEmail
    pocName
    pocContactNumber
    clanNeeded
    dartsNeeded
    dartsFormNeeded
    mandatoryTimeDistribution
  }
}`

















