import React, { useState } from "react";
import { Layout } from "antd";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Accounts_Query, Role_Account_Query } from "../services/userAPI";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Editicon from "../Images/Edit.png";
import SearchableDropdown from "../reusableField/searchableDropdownField";
import LoadingImage from "../Images/icon2.png"

const { Content } = Layout;

const Home = () => {
    const navigate = useNavigate();
    const [selectedRoles, setSelectedRoles] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [loadingRoles, setLoadingRoles] = useState({});
    const [imageError, setImageError] = useState({});
    const { loading: accountsLoading, error: accountsError, data: accountsData } = useQuery(Accounts_Query);

    const [fetchRoles] = useLazyQuery(Role_Account_Query, {
        onCompleted: (data) => {
            const accountId = data.getAllJobrole[0]?.account?.id || data.getAllJobrole?.accountId;
            if (accountId) {
                setSelectedRoles((prevRoles) => ({
                    ...prevRoles,
                    [accountId]: data.getAllJobrole,
                }));
                setLoadingRoles((prev) => ({
                    ...prev,
                    [accountId]: false,
                }));
            }
        },
        onError: (error) => {
            console.error("Error fetching roles:", error);
        },
    });

    const handleImageError = (userId) => {
        setImageError((prev) => ({
            ...prev,
            [userId]: true, // Set error flag for this image
        }));
    };

    const handleAccountChange = (accountId) => {
        if (!selectedRoles[accountId]) {
            fetchRoles({
                variables: { accountId: parseInt(accountId) },
            });
        }
    };

    if (accountsLoading) return (
        <div className="loading-container">
            <div className="spinner"></div>
        </div>
    );
    

    if (accountsError) return <div>Error: {accountsError.message}</div>;

    const handleEdit = (accountId, userData) => {
        navigate(`/create?accountId=${accountId}`, {
            state: {
                id: userData.id,
                name: userData.name,
                url: userData.url,
                address: userData.address,
                address2: userData.address2,
                brandColor: userData.brandColor,
                lenseNeeded: userData.lenseNeeded,
                dartsNeeded: userData.dartsNeeded,
                clanNeeded: userData.clanNeeded,
                emailLogin: userData.emailLogin,
                numberLogin: userData.numberLogin,
                imageUrl: userData.imageUrl,
                mainLogoUrl: userData.mainLogoUrl,
            }
        });
    };

    const handleRoleChange = (value, accountId) => {
        setSelectedValues((prev) => ({
            ...prev,
            [accountId]: value,
        }));
    };

    return (
        <Content className="content">
            <div className="home-content">
                <div className="cards-container">
                    {accountsData?.allUserAccount?.map((user) => (
                        <div key={user.id} className="card">
                            <div className="card-header">
                                {imageError[user.id] || !user.mainLogoUrl ? (
                                    <div className="placeholderImage">
                                        <span className="placeholder-text">No Image</span>
                                    </div>
                                ) : (
                                    <img
                                        src={user.mainLogoUrl}
                                        alt={user.name}
                                        className="card-image"
                                        onError={() => handleImageError(user.id)} // Handle error
                                    />
                                )}
                                <img
                                    src={Editicon}
                                    alt="Edit"
                                    className="edit-icon"
                                    onClick={() => handleEdit(user.id, user)} // Edit button triggers handleEdit
                                />
                            </div>
                            <h3 className="card-title">{user.name}</h3>

                            <div className="card-actions">
                                {loadingRoles[user.id] ? (
                                    <img
                                        src={LoadingImage}
                                        // alt="Loading..."
                                        className="loading-image"
                                    />
                                ) : (
                                    <SearchableDropdown
                                        options={selectedRoles[user.id] || []}
                                        label="name"
                                        id="id"
                                        selectedVal={selectedValues[user.id]}
                                        handleChange={(value) => handleRoleChange(value, user.id)}
                                        placeholder={user.numberOfRoles === 0 ? "No roles available" : "Select Role"}
                                        onFocus={() => handleAccountChange(user.id)} // Call fetchRoles on focus, not Edit button
                                        disabled={loadingRoles[user.id] || user.numberOfRoles === 0}
                                    />
                                )}
                                <button
                                    className={`view-role-button ${user.numberOfRoles === 0 ? 'disabled' : ''}`}
                                    onClick={() => handleEdit(user.id, user)} // Edit button triggers handleEdit
                                    disabled={user.numberOfRoles === 0}
                                >
                                    View Role
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Content>
    );
};

export default Home;
