import React, { useState } from "react";
import "./forgotPage.css"; // Importing the styles
import { Col } from "react-bootstrap";
import { BASE_URL } from "./apiConfig";
import { useNavigate } from "react-router-dom";
import Clanlogo from "../Images/clan_logo 1.png"
import Arrowleft from "../Images/Arrow left.png";
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Regex to check if the email is in a valid format (at least "@" and ".", with at least two characters after ".")
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

        if (emailRegex.test(emailValue.trim())) {
            setIsValid(true);
            setErrorMessage(""); // Clear error message if email is valid
        } else {
            setIsValid(false);
            setErrorMessage("Please enter valid email-id");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValid) return;

        setIsLoading(true); // Show a loading indicator while the API is called

        const requestBody = {
            username: email,
            domain: "https://admin.clanonline.in" // Add the domain
        };

        try {
            const response = await fetch("https://clanapilightsail.clanonline.in/clantestapi/forget_password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody), // Send the updated request body
            });

            if (response.ok) {
                // If the response is successful, navigate to the "Check Email" page
                const data = await response.json();
                setSuccessMessage("Kindly check your email inbox or spam folder for the password reset link."); // Show the success message from the response
                setErrorMessage(""); //

            } else if (response.status === 404) {
                // Handle error for unregistered email
                setErrorMessage("This email is not registered. Please try again.");
                setSuccessMessage(""); // Clear success message
                // Handle other server errors
            }
            else {
                const errorData = await response.json();
                const errorDetail = errorData.detail?.detail || "Something went wrong. Please try again."; // Accessing the nested error message
                setErrorMessage(errorDetail); // Set the error message from response
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Error during password reset request:", error);
            setErrorMessage("Unable to process your request. Please try again.");
            setSuccessMessage("");
        } finally {
            setIsLoading(false); // Hide the loading indicator
        }
    };

    return (
        <div className="reset-password-container">
            <div className="reset-password-form">
                <div style={{ border: "1px solid #c8c8c8", padding: "20px", borderRadius: "20px" }}>
                    <div>
                        <img src={Clanlogo} style={{ width: "33%" }} />
                    </div>
                    <span className="title-container">
                        <Link to="/login-page">
                            <img src={Arrowleft} alt="Arrow Left" className="arrow-icon" />
                        </Link>
                        <div className="reset-password-title">Forget Password</div>
                    </span>

                    <p className="reset-password-subtitle">
                        Please Enter Your Email Address To Receive A Password Reset Link.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <label className="email-label">Email-ID</label>
                        <div className="email-field">
                            <Col sm={6} xs={12} md={6} lg={3}>
                                <input
                                    type="email"
                                    className="email-input"
                                    placeholder="Enter Email-ID"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                            </Col>
                        </div>
                        {errorMessage && (
                            <p className="forget-password-error-message">
                                {errorMessage}
                            </p>
                        )}
                        {successMessage && (
                            <p className="forget-password-success-message" >
                                {successMessage}
                            </p>
                        )}
                        <button
                            type="submit"
                            className={`continue-btn ${isValid && !isLoading ? "active" : "inactive"}`}
                            disabled={!isValid || isLoading}
                        >
                            {isLoading ? "Processing..." : "Submit"}
                        </button>
                    </form>
                </div>
            </div>
        </div >
    );
};

export default ForgotPassword;
