import React, { useState, useEffect } from "react";
import { Layout, message } from "antd";
import { useQuery } from "@apollo/client";
import { Get_Lense_Account, Get_Lense_Account_Projects } from "../services/userAPI";
import { useNavigate } from "react-router-dom";
import "./createClient.css";
const { Content } = Layout;

const ImportFromLense = () => {
    const navigate = useNavigate();
    const [accountId, setAccountId] = useState(null);
    const { loading, error, data } = useQuery(Get_Lense_Account);

    const {
        data: projectsData,
        loading: projectsLoading,
        error: projectsError,
    } = useQuery(Get_Lense_Account_Projects, {
        variables: { clientId: accountId },
        skip: !accountId,
    });

    useEffect(() => {
        if (projectsData) {
            if (projectsData.getLenseAccountProjects) {
                const matchedProjects = projectsData.getLenseAccountProjects.filter(
                    (project) => project.clientId === accountId
                );
            }
        }
    }, [projectsData, accountId]);

    const handleEdit = (id, accountId, name, address, imageUrl, projectsData, role, isAdding = false) => {
        setAccountId(id);
        navigate(`/create-client-form?id=${id}`, {
            state: { id, name, accountId, address, imageUrl, projectsData, role,  addMode: isAdding  },
        });
    };

    if (loading) return <div>Loading Lense Accounts...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <Layout>
            <Content style={{ margin: "0 16px", marginTop: "0px" }}>
                <div className="create-client-container">
                    <h2>Lense Clients</h2>
                    <div className="cards-container">
                        {data.getLenseAccount?.map((account) => (
                            <div key={account.id} className="card">
                                <img
                                    src={account.imageUrl || "https://via.placeholder.com/80"}
                                    alt={account.name}
                                    className="card-image"
                                />
                                <h3 className="card-title">{account.name}</h3>
                                <p> {account.role}</p>
                                <div>
                                    <button
                                        className="card-button"
                                        onClick={() =>
                                            handleEdit(account.id, account.accountId, account.name, account.address, account.imageUrl, projectsData, account.role, true)
                                        }
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default ImportFromLense;
