import React from "react";
import { useQuery } from "@apollo/client";
import { Layout, Spin } from "antd";
import { Get_All_Job_Role } from "../services/userAPI";
import ReusableHeader from "../reusableField/ReusableHeader";
import { useSearchParams } from "react-router-dom";
import "./Home.css";

const { Content } = Layout;

const JobRoles = () => {
    const [searchParams] = useSearchParams();
    const accountId = searchParams.get("accountId");

    const { loading, error, data } = useQuery(Get_All_Job_Role, {
        variables: { accountId: parseInt(accountId, 10) },
    });

    if (loading) return <Spin size="large" style={{ display: "block", margin: "50px auto" }} />;
    if (error) return <div>Error: {error.message}</div>;

    const accountDetails = data.getAllJobrole[0]?.account || {}; // Assuming roles share the same account

    return (
        <Layout>
            <Content style={{ margin: "16px" }}>
                <div className="home-content">
                    {/* Header with Client Information */}
                    <div className="client-header-wrapper">
                        <div className="logo-section">
                            <img
                                src={accountDetails.mainLogoUrl || "https://via.placeholder.com/150"}
                                alt={accountDetails.name}
                                className="client-logo"
                            />
                        </div>

                        <div className="info-section">
                            <h1 className="company-name">{accountDetails.name}</h1>
                            <div className="details-list">
                                <div className="details-item">
                                    <strong>Website:</strong>
                                    <a href={accountDetails.url} target="_blank" rel="noopener noreferrer">{accountDetails.url}</a>
                                </div>
                                <div className="details-item">
                                    <strong>Email Login:</strong> {accountDetails.emailLogin ? "Enabled" : "Disabled"}
                                </div>
                                <div className="details-item">
                                    <strong>Phone Login:</strong> {accountDetails.numberLogin ? "Enabled" : "Disabled"}
                                </div>
                                <div className="details-item">
                                    <strong>Office Address:</strong> {accountDetails.address}
                                </div>
                                <div className="details-item">
                                    <strong>Brand Color:</strong>
                                    <span
                                        className="brand-color-swatch"
                                        style={{ backgroundColor: `#${accountDetails.brandColor}` }}
                                    ></span>
                                    {`#${accountDetails.brandColor}`}
                                </div>

                                <div className="details-item">
                                    <strong>Self Registration:</strong> {accountDetails.selfRegistrationLogin ? "Enabled" : "Disabled"}
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* Job Roles */}
                    <div className="heading">Roles</div>
                    <div className="cards-container">
                        {data.getAllJobrole.map((role) => (
                            <div key={role.id} className="card">
                                <img
                                    src={accountDetails.mainLogoUrl || "https://via.placeholder.com/80"}
                                    alt={role.name}
                                    className="card-image"
                                />
                                <h3 className="card-title">{role.name}</h3>
                                <div className="inline-buttons">
                                    {role.clanNeeded && <button className="small-button">Clan</button>}
                                    {role.dartsNeeded && <button className="small-button">Darts</button>}
                                    {role.dartsFormNeeded && <button className="small-button">Form</button>}
                                </div>
                                <button className="card-button">View</button>
                            </div>
                        ))}
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default JobRoles;
