import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// GraphQL API Endpoint
const GRAPHQL_ENDPOINT = "https://clanapilightsail.clanonline.in/clantestapi/graphql";

// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
    }
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        if (networkError.statusCode === 401) {
            localStorage.removeItem('flutter.accessToken'); // Clear token
            window.location.href = "/login-page"; // Redirect to login
        }
    }
});

// Authentication link
const authLink = setContext((_, { headers }) => {
    // Get token from localStorage
    const token = localStorage.getItem("flutter.accessToken");
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

// Upload link for file uploads
const uploadLink = createUploadLink({
    uri: GRAPHQL_ENDPOINT,
});

// Combine the links
const link = ApolloLink.from([errorLink, authLink, uploadLink]);

// Apollo Client configuration
const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "network-only",
            errorPolicy: "ignore",
        },
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        },
    },
});

export default client;
